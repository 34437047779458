import { Component } from '@angular/core';
import { DismissibleNoticeStyle } from '../dismissible-notice.component';

@Component({
  selector: 'app-stripe-dismissible-notice',
  templateUrl: './stripe-dismissible-notice.component.html',
  styleUrls: ['./stripe-dismissible-notice.component.scss'],
})
export class StripeDismissibleNoticeComponent {
  readonly DismissibleNoticeStyle = DismissibleNoticeStyle;
}
