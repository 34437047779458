import { AbstractControl, ValidationErrors } from '@angular/forms';

// Higher order function that returns a validator to compare password with confirm password
export const confirmPasswordValidator = (passwordControl: AbstractControl) => {
  return (control: AbstractControl): ValidationErrors | null => {
    // Passwords match, no errors
    if (control.value === passwordControl.value) {
      return null;
    }

    // Passwords mismatch, errors
    return {
      confirmPassword: 'Passwords do not match',
    };
  };
};
