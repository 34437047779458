import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';

@Component({
  selector: 'app-close-account-dialog',
  templateUrl: './close-account-dialog.component.html',
  styleUrls: ['./close-account-dialog.component.scss'],
})
export class CloseAccountDialogComponent {
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  constructor(public matDialogRef: MatDialogRef<CloseAccountDialogComponent>) {}
}
