import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, filter, switchMap, tap } from 'rxjs';
import { AddOrganizationMemberDialogComponent } from 'src/app/_dialog/add-organization-member-dialog/add-organization-member-dialog.component';
import { ConfirmationDialogData } from 'src/app/_dialog/confirmation-dialog/confirmation-dialog.component';
import { EditUserDialogComponent } from 'src/app/_dialog/edit-user-dialog/edit-user-dialog.component';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';
import { ConfirmationService } from 'src/app/_services/confirmation.service';
import { ButtonPadding, ButtonStyle, ButtonType } from '../../button/button.component';
import { SkeletonLoaderRounding } from '../../skeleton-loader/skeleton-loader.component';

interface DescriptionTemplateContext {
  $implicit: User;
}

@Component({
  selector: 'app-organization-members-settings',
  templateUrl: './organization-members-settings.component.html',
  styleUrls: ['./organization-members-settings.component.scss'],
})
export class OrganizationMembersSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate') headingTemplate: TemplateRef<{}>;
  @ViewChild('descriptionTemplate') descriptionTemplate: TemplateRef<DescriptionTemplateContext>;

  private refreshUsers$ = new Subject<void>();

  readonly tableColumns: string[] = ['name', 'role', 'actions'];
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  loading: boolean = false;
  users: User[] = [];

  constructor(
    private accountService: AccountService,
    private matDialog: MatDialog,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.refreshUsers$
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this.accountService.getUsers())
      )
      .subscribe({
        next: (users) => {
          this.users = users;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });

    this.refreshUsers$.next();
  }

  ngOnDestroy(): void {
    this.refreshUsers$.complete();
  }

  onDelete(user: User): void {
    const dialogData: ConfirmationDialogData = {
      headingTemplate: this.headingTemplate,
      descriptionTemplate: this.descriptionTemplate,
      descriptionContext: {
        $implicit: user,
      },
    };

    this.confirmationService
      .confirm(dialogData)
      .pipe(
        filter((confirmed) => confirmed),
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this.accountService.deleteUser(user.id))
      )
      .subscribe({
        next: () => {
          this.users = this.users.filter(({ id }) => id !== user.id);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onEdit(user: User): void {
    this.matDialog
      .open(EditUserDialogComponent, { data: { user } })
      .afterClosed()
      .subscribe((userModified) => {
        if (userModified) {
          this.refreshUsers$.next();
        }
      });
  }

  onAddMember(): void {
    const dialogRef = this.matDialog.open(AddOrganizationMemberDialogComponent);

    dialogRef.afterClosed().subscribe((userAdded) => {
      if (userAdded) {
        this.refreshUsers$.next();
      }
    });
  }
}
