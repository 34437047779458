<div
  [ngClass]="{
    'text-primary-700 border-primary-700 bg-primary-50':
      clusterStatus === ClusterStatus.CREATING ||
      clusterStatus === ClusterStatus.STOPPING ||
      clusterStatus === ClusterStatus.STARTING ||
      clusterStatus === ClusterStatus.DESTROYING ||
      clusterStatus === ClusterStatus.UPGRADING,

    'text-gray-700 border-gray-700 bg-gray-50':
      clusterStatus === ClusterStatus.STOPPED ||
      clusterStatus === ClusterStatus.DESTROYED ||
      clusterStatus === ClusterStatus.IDLE,

    'text-error-700 border-error-700 bg-error-50':
      clusterStatus === ClusterStatus.CREATION_FAILED || clusterStatus === ClusterStatus.ERROR,

    'text-success-700 border-success-700 bg-success-50': clusterStatus === ClusterStatus.RUNNING
  }"
  class="inline-flex items-center gap-1 px-1.5 py-0.5 border rounded-full"
>
  <app-cluster-status-icon [clusterStatus]="clusterStatus" class="text-xs"></app-cluster-status-icon>

  <div class="text-xs leading-xs font-medium">{{ textByClusterStatus[clusterStatus] }}</div>
</div>
