import { Component, ElementRef, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/_services/account.service';
import { PortalService } from 'src/app/_services/portal.service';
import {Observable} from "rxjs";
import {BillingService} from "../../_services/billing.service";

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent {
  @ViewChild('scrollableContainer', { static: true }) scrollableContainer: ElementRef<HTMLDivElement>;

  readonly clusters$ = this._portalService.getClusterList();
  readonly user$ = this._accountService.user;

  billing$ = this._billingService.getBilling();

  constructor(private _portalService: PortalService, private _accountService: AccountService, private _billingService: BillingService) {}

  onLogout(): void {
    this._accountService.logout();
  }
}
