export interface Quota {
  quotaKey: string;
  requestedValue?: number;
  requestedReason?: string;
  requestSend?: boolean;
}

export enum QuotaKey {
  AGENTS_PER_CLUSTER = 'AGENTS_PER_CLUSTER',
}
