import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { SwiperContainer } from 'swiper/swiper-element';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.scss'],
})
export class TabsetComponent implements OnInit, OnChanges {
  @Input() activeTabIndex: number = 0;

  @Output() activeTabIndexChange = new EventEmitter<number>();

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @ViewChild('swiperContainer', { static: true }) swiperContainer: ElementRef<SwiperContainer>;

  ngOnInit(): void {
    this.swiperContainer.nativeElement.swiper.disable();
  }

  ngOnChanges(): void {
    this.setActiveTabIndex(this.activeTabIndex);
  }

  setActiveTabIndex(activeTabIndex: number): void {
    this.activeTabIndex = activeTabIndex;
    this.swiperContainer.nativeElement.swiper.enable();
    this.swiperContainer.nativeElement.swiper.slideTo(activeTabIndex);
    this.swiperContainer.nativeElement.swiper.disable();
  }
}
