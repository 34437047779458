import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {interval, Subscription} from "rxjs";
import {ClusterStatus} from "../../_models/cluster";
import {SpinnerService} from "../../_services/spinner.service";

@Component({
  selector: 'app-start-cluster',
  templateUrl: './wake-cluster.component.html',
  styleUrls: ['./wake-cluster.component.scss']
})
export class WakeClusterComponent implements OnInit {

  starting: boolean;
  clusterStatus: Subscription;
  clusterName: string;
  error: any;

  constructor(private route: ActivatedRoute, private http: HttpClient, private spinnerService: SpinnerService,) {
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    // We only start after receiving mouse input to prevent unintended cluster wake-ups (either in browser tabs or by robots)
    if (this.starting) {
      return;
    }

    this.spinnerService.show('Your cluster is starting - this process may take up to 5 minutes. Billing only starts once it is running.');
    this.starting = true;
    this.wakeCluster()?.subscribe(() => {
        this.clusterStatus = interval(5000).subscribe((x => {
          this.checkStatus().subscribe((status: string) => {
            if (status === ClusterStatus[ClusterStatus.RUNNING]) {
              this.clusterStatus.unsubscribe();
              this.getClusterUri().subscribe((clusterUri: string) => {
                if (!clusterUri.includes('://')) {
                  clusterUri = 'https://' + clusterUri;
                }
                window.location.href = clusterUri;
              })
            } else if (status !== ClusterStatus[ClusterStatus.STOPPED] && status !== ClusterStatus[ClusterStatus.STARTING]) {
              console.error('waking cluster', `cluster ${this.clusterName} is in error state`);
              this.spinnerService.hide();
              this.error = {errorMessage: "Cluster failed to start. This is a technical error and our team was automatically notified. Contact us on support@exense.ch for urgent support."};
              this.clusterStatus.unsubscribe();
            }
          });
        }));
      }, (error) => {
        this.error = error.error || error;
        console.error('Error when waking up cluster', error)
        this.spinnerService.hide();
      }
    )
  }

  ngOnInit(): void {
    this.getClusterName().subscribe(clusterName => {
      this.clusterName = clusterName;
    });
  }

  private getClusterName() {
    return this.route.queryParams.pipe(
      filter(params => !!params.clusterName),
      map((params) => params.clusterName),
    )
  }

  private getClusterUri() {
    return this.route.queryParams.pipe(
      filter(params => !!params.clusterUri),
      map((params) => params.clusterUri),
    )
  }

  private wakeCluster() {
    if (!this.clusterName) {
      return;
    }

    return this.http.post(`${environment.apiUrl}/cluster/${this.clusterName}/wake`,
      {},
      {headers: new HttpHeaders({'Content-Type': 'application/json'})});
  }

  private checkStatus() {
    return this.http.get<string>(`${environment.apiUrl}/cluster/${this.clusterName}/status`,
      {responseType: 'text' as 'json'});
  }

}
