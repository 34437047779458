import { Component, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap } from 'rxjs';
import { AccountService } from 'src/app/_services/account.service';
import { BillingService } from 'src/app/_services/billing.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { CardSettingsFormSubmitParams } from '../card-settings/card-settings.component';

const PAYMENT_METHOD_SETUP_SUCCESS = 'Payment method added successfully, finalizing billing setup now...';
const PAYMENT_METHOD_SETUP_ERROR = 'Something went wrong while processing payment method, please try again.';

@Component({
  selector: 'app-billing-details-step',
  templateUrl: './billing-details-step.component.html',
  styleUrls: ['./billing-details-step.component.scss'],
})
export class BillingDetailsStepComponent {
  @Output() stepComplete = new EventEmitter<void>();

  constructor(
    private accountService: AccountService,
    private billingService: BillingService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService
  ) {}

  onSubmit({ formValue, stripeInstance }: CardSettingsFormSubmitParams): void {
    this.spinnerService.show();

    this.billingService
      .initPaymentSetup()
      .pipe(
        switchMap(({ clientSecret }) =>
          stripeInstance.confirmCardSetup(clientSecret, {
            payment_method: {
              card: formValue.cardNumberElement,
              billing_details: {
                email: this.accountService.userValue?.email,
                // Use the cardholder name instead of `userValue?.name` as these are billing details
                name: formValue.cardName,
              },
            },
          })
        ),
        tap(({ error }) => {
          if (error) {
            throw new Error(error.message);
          }

          this.toastrService.success(PAYMENT_METHOD_SETUP_SUCCESS);
        }),
        switchMap(() => this.billingService.getCustomerWithPayment()),
        tap(({ default_payment_method }) => {
          if (!default_payment_method) {
            throw new Error(PAYMENT_METHOD_SETUP_ERROR);
          }
        }),
        // Refresh the organization
        switchMap(() => this.accountService.getOrganization())
      )
      .subscribe({
        next: () => {
          this.spinnerService.hide();
          this.stepComplete.emit();
        },
        error: (error) => {
          this.spinnerService.hide();
          this.toastrService.error(error);
        },
      });
  }
}
