<div class="flex items-start gap-5">
  <div class="flex-1 p-4 border border-gray-200 rounded-lg flex items-start gap-3">
    <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
      <img [src]="agent.imgSrc" [alt]="agent.imgAlt" class="w-full h-full" />
    </div>

    <div class="flex-1 text-sm leading-sm">
      <div class="font-medium text-gray-700">{{ agent.title }}</div>
      <div class="text-gray-500 pb-2">{{ agent.description }}</div>

      <div class="pt-4 pb-3">
        <mat-slider
          [min]="0"
          [max]="remainingQuota"
          [step]="1"
          [thumbLabel]="true"
          class="w-full"
        ><input matSliderThumb [formControl]="sliderControl | asFormControl" #slider/></mat-slider>
        {{ slider.value }}
      </div>

      <div class="pb-3">
        <hr />
      </div>

      <button
        type="button"
        (click)="agentRemove.emit(agent.type)"
        class="font-medium text-gray-500 focus:outline-none focus:text-primary-400"
      >
        Remove
      </button>
    </div>
  </div>

  <ng-container *ngIf="customerPrices ? customerPrices : {} as customerPrices">
    <ng-container
      *ngIf="
        sliderControl.value * (customerPrices[agent.type].amount / 100) * billingPeriod.minutes
          | currency : '' : '' : billingPeriod.decimalPlaces as amount
      "
    >
      <app-costs [amount]="amount" [unit]="billingPeriod.unit"></app-costs>
    </ng-container>
  </ng-container>
</div>
