import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Address } from 'src/app/_models/address';
import { AccountService } from 'src/app/_services/account.service';
import { CountriesService, Country } from 'src/app/_services/countries.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { AddressSettingsComponent, AddressSettingsFormValue } from '../address-settings/address-settings.component';

@Component({
  selector: 'app-billing-address-step',
  templateUrl: './billing-address-step.component.html',
  styleUrls: ['./billing-address-step.component.scss'],
})
export class BillingAddressStepComponent implements OnInit {
  @Output() stepComplete = new EventEmitter<void>();

  // Used to trigger invalidity indication from the outside using `requestSubmit`
  // See `add-payment-wizard-dialog.component.ts`
  @ViewChild(AddressSettingsComponent) addressSettingsComponent: AddressSettingsComponent;

  countries: Country[] = [];
  loading: boolean = false;

  constructor(
    private accountService: AccountService,
    private spinnerService: SpinnerService,
    private countriesService: CountriesService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.countriesService.getCountries().subscribe({
      next: (countries) => {
        this.countries = countries;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  onSubmit(formValue: AddressSettingsFormValue): void {
    if (this.spinnerService.visible) {
      return;
    }

    this.spinnerService.show();

    const { street, city, postalCode, state, country } = formValue;

    const address = {
      street,
      city,
      postalCode,
      state,
      country,
    };

    this.accountService.updateOrganization(address).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.stepComplete.emit();
        this.addressSettingsComponent.initialFormValue = {
          street,
          city,
          postalCode,
          state,
          country,
        };
        this.addressSettingsComponent.onReset();
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
