import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { RequireVerificationDialogData } from 'src/app/_dialog/require-verification-dialog/require-verification-dialog-data.interface';
import { confirmPasswordValidator } from 'src/app/_helpers/validators';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';
import { RequireVerificationDialogComponent } from '../../_dialog/require-verification-dialog/require-verification-dialog.component';
import { AccountService } from '../../_services/account.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  private terminator$ = new Subject<void>();

  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this.formBuilder.group({
    organizationName: ['', Validators.required],
    fullName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    termsOfUse: ['', Validators.requiredTrue],
  });

  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmPassword.addValidators(confirmPasswordValidator(this.formGroup.controls.password));

    this.formGroup.controls.password.valueChanges.pipe(takeUntil(this.terminator$)).subscribe(() => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
  }

  onSubmit(): void {
    // Explicitly mark the checkbox control as touched to show required indication (as it does not happen out-of-the-box)
    this.formGroup.controls.termsOfUse.markAsTouched();

    // Form is invalid - do nothing
    if (this.formGroup.invalid) {
      return;
    }

    this.spinnerService.show();

    const { organizationName, fullName, email, password } = this.formGroup.value;

    this.accountService.register({ organizationName, fullName, email, password }).subscribe({
      next: () => {
        this.dialog.open<RequireVerificationDialogComponent, RequireVerificationDialogData>(
          RequireVerificationDialogComponent,
          {
            data: {
              email,
              password,
            },
          }
        );

        this.spinnerService.hide();
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
