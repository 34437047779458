import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs';
import { RegistrationState } from 'src/app/_models/registration-state.enum';
import { AccountService } from 'src/app/_services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';

@Component({
  selector: 'app-add-payment-dialog',
  templateUrl: './add-payment-dialog.component.html',
  styleUrls: ['./add-payment-dialog.component.scss'],
})
export class AddPaymentDialogComponent {
  readonly registrationState$ = this.accountService.organization.pipe(
    map((organization) => organization.registrationState)
  );
  readonly RegistrationState = RegistrationState;
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  constructor(private accountService: AccountService, private matDialogRef: MatDialogRef<AddPaymentDialogComponent>) {}

  onCancel(): void {
    this.matDialogRef.close(false);
  }

  onAddPayment(): void {
    this.matDialogRef.close(true);
  }
}
