<app-svg-icon
  [name]="iconByClusterStatus[clusterStatus]"
  [ngClass]="{
    'animate-spin':
      clusterStatus === ClusterStatus.CREATING ||
      clusterStatus === ClusterStatus.STOPPING ||
      clusterStatus === ClusterStatus.STARTING ||
      clusterStatus === ClusterStatus.DESTROYING ||
      clusterStatus === ClusterStatus.UPGRADING
  }"
  class="block"
></app-svg-icon>
