import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, combineLatest, interval, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { AddPaymentWizardDialogComponent } from '../_dialog/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { Billing } from '../_models/billing';
import { CardDetails } from '../_models/card-details';
import { BillingService, DataLine } from '../_services/billing.service';
import { SETTINGS_ACTIVE_TAB_QUERY_PARAM, SettingsTabs } from '../components/settings/settings.component';
import { SkeletonLoaderRounding } from '../components/skeleton-loader/skeleton-loader.component';

const POLLING_INTERVAL = 10000;

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy {
  readonly billingTableColumns: string[] = ['description', 'quantity', 'price', 'amount'];
  readonly invoicesTableColumns: string[] = ['name', 'date', 'paid', 'total', 'action'];
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  private terminator$ = new Subject<void>();
  private refreshCardDetails$ = new Subject<void>();

  loading: boolean = false;
  cardDetailsLoading: boolean = false;
  initialLoad: boolean = true;
  dataLines: DataLine[] = [];
  invoices: Billing[] = [];
  cardDetails?: CardDetails;
  currentInvoice?: any;

  constructor(private billingService: BillingService, public router: Router, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.loading = true;
    this.cardDetailsLoading = true;

    combineLatest([
      interval(POLLING_INTERVAL).pipe(
        startWith(0),
        switchMap(() => this.billingService.getBilling())
      ),
      this.refreshCardDetails$.pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this.billingService.getCardsDetails())
      ),
      this.billingService.getInvoices().pipe(
        tap(() => {
          this.cardDetailsLoading = true;
        })
      ),
    ])
      .pipe(takeUntil(this.terminator$))
      .subscribe({
        next: ([
          currentInvoice,
          [cardDetails],
          invoices,
        ]) => {
          this.dataLines = currentInvoice.lines.data;
          this.currentInvoice = currentInvoice;
          this.cardDetails = cardDetails;
          this.invoices = invoices;
          this.loading = false;
          this.cardDetailsLoading = false;
          this.initialLoad = false;
          },
        error: (error) => {
          this.loading = false;
          this.cardDetailsLoading = false;
          console.log('error loading billing', error);

        },
      });

    this.refreshCardDetails$.next();
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
    this.refreshCardDetails$.complete();
  }

  onEditPaymentMethod(): void {
    this.router.navigate(['/settings'], {
      queryParams: {
        [SETTINGS_ACTIVE_TAB_QUERY_PARAM]: SettingsTabs.PAYMENT_METHOD,
      },
    });
  }

  onAddPaymentMethod(): void {
    const dialogRef = this.matDialog.open(AddPaymentWizardDialogComponent, {
      // See `mat-dialog.scss`
      panelClass: 'dialog-full-screen',
    });

    dialogRef.afterClosed().subscribe((wizardCompleted) => {
      if (!wizardCompleted) {
        return;
      }

      this.refreshCardDetails$.next();
    });
  }
}
