import { Component, Input } from '@angular/core';
import { ClusterStatus } from 'src/app/_models/cluster';

@Component({
  selector: 'app-cluster-status-icon',
  templateUrl: './cluster-status-icon.component.html',
  styleUrls: ['./cluster-status-icon.component.scss'],
})
export class ClusterStatusIconComponent {
  @Input() clusterStatus!: ClusterStatus;

  readonly iconByClusterStatus: Record<ClusterStatus, string> = {
    [ClusterStatus.CREATING]: 'refresh-cw-02',
    [ClusterStatus.CREATION_FAILED]: 'x-circle',
    [ClusterStatus.RUNNING]: 'check-circle',
    [ClusterStatus.STOPPING]: 'refresh-cw-02',
    [ClusterStatus.STOPPED]: 'minus-circle',
    [ClusterStatus.STARTING]: 'refresh-cw-02',
    [ClusterStatus.DESTROYING]: 'refresh-cw-02',
    [ClusterStatus.DESTROYED]: 'x-circle',
    [ClusterStatus.ERROR]: 'x-circle',
    [ClusterStatus.IDLE]: 'check-circle',
    [ClusterStatus.UPGRADING]: 'refresh-cw-02',
  };
  readonly ClusterStatus = ClusterStatus;
}
