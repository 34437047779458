<app-dismissible-notice
  storageKey="paymentsDismissibleNotice"
  icon="info-circle"
  [dismissibleNoticeStyle]="DismissibleNoticeStyle.NOTICE_1"
>
  <p class="text-sm leading-sm font-medium text-gray-700">
    Payments are billed to your credit card at the end of the month, or once you reach 50 CHF, depending on your usage. You can find real time
    usage and cost in billing and stop your clusters anytime.
  </p>
</app-dismissible-notice>
