export class CardDetails {
  constructor(public brand: CardBrand, public last4: string, public expMonth: number, public expYear: number) {}
}

export enum CardBrand {
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  UNIONPAY = 'UNIONPAY',
  VISA = 'VISA',
  UNKNOWN = 'UNKNOWN',
}
