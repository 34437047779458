<h3 class="text-lg leading-lg font-medium text-gray-900 pb-1">Step 1</h3>

<p class="text-sm leading-sm text-gray-500">Add your billing address</p>

<div class="pt-5">
  <hr />
</div>

<app-address-settings
  submitButtonTitle="Add billing address"
  [countries]="countries"
  [loading]="loading"
  (formSubmit)="onSubmit($event)"
  class="block py-12"
></app-address-settings>
