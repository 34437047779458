import { Component, Input } from '@angular/core';
import { CardBrand } from 'src/app/_models/card-details';

@Component({
  selector: 'app-card-brand',
  templateUrl: './card-brand.component.html',
  styleUrls: ['./card-brand.component.scss'],
})
export class CardBrandComponent {
  @Input() cardBrand?: CardBrand;

  readonly cardLogoSrcByCardBrand: { [key in CardBrand]?: string } = {
    [CardBrand.AMEX]: '/assets/svg/logos/amex.logo.svg',
    [CardBrand.DINERS]: '/assets/svg/logos/diners.logo.svg',
    [CardBrand.DISCOVER]: '/assets/svg/logos/discover.logo.svg',
    [CardBrand.JCB]: '/assets/svg/logos/jcb.logo.svg',
    [CardBrand.MASTERCARD]: '/assets/svg/logos/mastercard.logo.svg',
    [CardBrand.UNIONPAY]: '/assets/svg/logos/unionpay.logo.svg',
    [CardBrand.VISA]: '/assets/svg/logos/visa.logo.svg',
  };

  readonly cardLogoAltByCardBrand: { [key in CardBrand]?: string } = {
    [CardBrand.AMEX]: 'American Express logo',
    [CardBrand.DINERS]: 'Diners logo',
    [CardBrand.DISCOVER]: 'Discover logo',
    [CardBrand.JCB]: 'JCB logo',
    [CardBrand.MASTERCARD]: 'MasterCard logo',
    [CardBrand.UNIONPAY]: 'UnionPay logo',
    [CardBrand.VISA]: 'Visa logo',
  };
}
