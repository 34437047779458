import { Component } from '@angular/core';
import { DismissibleNoticeStyle } from '../dismissible-notice.component';

@Component({
  selector: 'app-payments-dismissible-notice',
  templateUrl: './payments-dismissible-notice.component.html',
  styleUrls: ['./payments-dismissible-notice.component.scss'],
})
export class PaymentsDismissibleNoticeComponent {
  readonly DismissibleNoticeStyle = DismissibleNoticeStyle;
}
