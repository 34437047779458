import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BillingPeriod, CustomerPrices } from 'src/app/_models/billing';
import { Agent, AgentType } from 'src/app/_services/agents.token';

@Component({
  selector: 'app-agent-configuration',
  templateUrl: './agent-configuration.component.html',
  styleUrls: ['./agent-configuration.component.scss'],
})
export class AgentConfigurationComponent {
  @Input() agent!: Agent;
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() sliderControl = new UntypedFormControl(1);
  @Input() remainingQuota!: number;

  @Output() agentRemove = new EventEmitter<AgentType>();
}
