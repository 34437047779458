import { Component, Input } from '@angular/core';
import { Plugin } from 'src/app/_services/plugins.token';

@Component({
  selector: 'app-plugin-details',
  templateUrl: './plugin-details.component.html',
  styleUrls: ['./plugin-details.component.scss'],
})
export class PluginDetailsComponent {
  @Input() plugin!: Plugin;
}
