import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcTime',
})
export class CalcTimePipe implements PipeTransform {
  transform(timeInMin: number): string {
    if (!timeInMin) {
      return '';
    }

    const minutes = timeInMin % 60;

    timeInMin -= minutes;

    const hours = (timeInMin % (60 * 24)) / 60;
    const days = Math.floor(timeInMin / (60 * 24));

    let readableTime: string[] = [];

    if (days > 0) {
      readableTime.push(`${days} days`);
    }

    if (hours === 1) {
      readableTime.push(`${hours} hour`);
    } else if (hours > 0) {
      readableTime.push(`${hours} hours`);
    }

    if (minutes === 1) {
      readableTime.push(`${minutes} minute`);
    } else {
      readableTime.push(`${minutes} minutes`);
    }

    return readableTime.join(' ');
  }
}
