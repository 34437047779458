import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/_services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from '../../button/button.component';

@Component({
  selector: 'app-user-personal-info-settings',
  templateUrl: './user-personal-info-settings.component.html',
  styleUrls: ['./user-personal-info-settings.component.scss'],
})
export class UserPersonalInfoSettingsComponent implements OnInit {
  readonly formGroup = this.formBuilder.group({
    organizationName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  constructor(private formBuilder: UntypedFormBuilder, private accountService: AccountService) {}

  ngOnInit() {
    this.onReset();
  }

  onReset(): void {
    if (!this.accountService.userValue || !this.accountService.organizationValue) {
      return;
    }

    const { email } = this.accountService.userValue;
    const { name: organizationName } = this.accountService.organizationValue;

    this.formGroup.reset({
      organizationName,
      email,
    });

    this.formGroup.disable();
  }
}
