import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { transitionMessages } from 'src/app/_animations/transition-messages.animation';
import { Country } from 'src/app/_services/countries.service';
import { ButtonPadding, ButtonStyle, ButtonType } from '../button/button.component';
import { SkeletonLoaderRounding } from '../skeleton-loader/skeleton-loader.component';

export interface AddressSettingsFormValue {
  street: string;
  city: string;
  postalCode: string;
  state: string;
  country?: Country | null;
  phoneNumber?: string | null;
}

@Component({
  selector: 'app-address-settings',
  templateUrl: './address-settings.component.html',
  styleUrls: ['./address-settings.component.scss'],
  animations: [transitionMessages],
})
export class AddressSettingsComponent implements OnInit, OnChanges {
  @Input() countries!: Country[];
  @Input() submitButtonTitle!: string;
  @Input() initialFormValue?: AddressSettingsFormValue;
  @Input() withPhoneNumber?: boolean;
  @Input() loading?: boolean;

  @Output() formSubmit = new EventEmitter<AddressSettingsFormValue>();

  @ViewChild('form') form: ElementRef<HTMLFormElement>;

  readonly formGroup = this.formBuilder.group({
    street: ['', Validators.required],
    city: ['', Validators.required],
    postalCode: ['', Validators.required],
    state: [''],
    country: ['', Validators.required],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.withPhoneNumber) {
      this.formGroup.addControl('phoneNumber', this.formBuilder.control(''));
    }
  }

  ngOnChanges(): void {
    if (!this.initialFormValue) {
      return;
    }

   this.onReset();
  }

  onReset(): void {
    this.formGroup.reset({
      ...(this.initialFormValue ?? {}),
    });
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.formSubmit.emit(this.formGroup.value);
  }
}
