import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.scss'],
})
export class DialogBodyComponent {
  @ContentChild('heading') headingContent: TemplateRef<{}>;
  @ContentChild('description') descriptionContent: TemplateRef<{}>;
}
