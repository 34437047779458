export interface Billing {
  total: number;
  starting_balance: number;
  currency: string;
  balance: number;
  number: string;
  created: number;
  amount_due: number;
  amount_paid: number;
  invoice_pdf: string;
  lines: { data: Array<any> };
}

export enum BillingType {
  CONTROLLER_USAGE = 'CONTROLLER_USAGE',
  AGENT_USAGE = 'AGENT_USAGE',
  CONTROLLER_PLUGIN = 'CONTROLLER_PLUGIN',
}

export interface BillingItem {
  type: BillingType;
  key: string;
}

export enum BillingPeriodType {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
}

export interface BillingPeriod {
  unit: string;
  minutes: number;
  decimalPlaces: string;
}

export class PriceInfo {
  constructor(
    public amount: number,
    public billingDescription: string,
    public description: string,
    public fullDescription: string,
    public order: number,
    public type: BillingType
  ) {}
}

export type CustomerPrices = Record<string, PriceInfo>;
