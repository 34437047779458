import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseAccountDialogComponent } from 'src/app/_dialog/close-account-dialog/close-account-dialog.component';
import { ButtonPadding, ButtonStyle, ButtonType } from '../../button/button.component';

@Component({
  selector: 'app-organization-account-settings',
  templateUrl: './organization-account-settings.component.html',
  styleUrls: ['./organization-account-settings.component.scss'],
})
export class OrganizationAccountSettingsComponent {
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  constructor(private matDialog: MatDialog) {}

  onCloseAccount(): void {
    this.matDialog.open(CloseAccountDialogComponent);
  }
}
