<app-dialog-header icon="slash-circle-01" (dialogClose)="matDialogRef.close()"></app-dialog-header>

<app-dialog-body>
  <ng-template #heading>
    <a href="https://step.dev/contact/" target="_blank" class="link-0">Contact us</a>
    so we can close the account for you.
  </ng-template>

  <ng-template #description>
    In the meantime we advice you to stop or delete all clusters to prevent any costs.
  </ng-template>

  <app-button
    [buttonType]="ButtonType.BUTTON"
    [buttonStyle]="ButtonStyle.BTN_1"
    [buttonPadding]="ButtonPadding.BTN_PADDING_0"
    [fluid]="true"
    (buttonClick)="matDialogRef.close()"
  >
    Close
  </app-button>
</app-dialog-body>
