import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegistrationState } from 'src/app/_models/registration-state.enum';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';
import { AccountService } from '../../_services/account.service';
import { RequireVerificationDialogData } from './require-verification-dialog-data.interface';

@Component({
  selector: 'app-require-verification-dialog',
  templateUrl: './require-verification-dialog.component.html',
  styleUrls: ['./require-verification-dialog.component.scss'],
})
export class RequireVerificationDialogComponent {
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;

  constructor(
    private accountService: AccountService,
    private router: Router,
    public matDialogRef: MatDialogRef<RequireVerificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private matDialogData: RequireVerificationDialogData
  ) {}

  onContinue(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const { email, password } = this.matDialogData;

    this.accountService.login(email, password).subscribe({
      next: ({ organization }) => {
        if (organization?.registrationState !== RegistrationState.NEW) {
          this.router.navigate(['/cluster']);
          this.matDialogRef.close();
        } else {
          this.onContinue();
          return;
        }

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
