import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../_dialog/spinner-dialog/spinner-dialog.component';

// See `mat-dialog.scss`
const SPINNER_DIALOG_PANEL_CLASS = 'spinner-dialog';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private matDialogRef: MatDialogRef<SpinnerDialogComponent> | null = null;

  visible: boolean = false;

  constructor(private matDialog: MatDialog) {}

  show(message? : string): void {
    // Prevent overlapping spinner dialogs by showing only one at a time
    if (this.matDialogRef) {
      return;
    }

    this.matDialogRef = this.matDialog.open(SpinnerDialogComponent, {
      panelClass: SPINNER_DIALOG_PANEL_CLASS,
      disableClose: true,
    });

    if (message) {
      this.matDialogRef.componentInstance.setMessage(message);
    }

    this.visible = true;
  }

  hide(): void {
    this.matDialogRef?.close();
    this.matDialogRef = null;
    this.visible = false;
  }
}
