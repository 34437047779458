<div class="flex items-center justify-between gap-8 p-8">
  <span class="text-sm leading-sm text-gray-500">© Step 2024</span>

  <div class="inline-flex items-center gap-3 text-sm leading-sm text-gray-500">
    <button type="button" matRipple [matMenuTriggerFor]="supportMenu" class="bg-gray-100 rounded-full p-2 focus-ring-primary-1">
      <app-svg-icon name="life-buoy-02"></app-svg-icon>
    </button>

    <span>Support</span>
  </div>
</div>

<mat-menu #supportMenu="matMenu">
  <a href="https://step.dev/knowledgebase/" target="_blank" mat-menu-item class="!flex items-center gap-3">
    <app-svg-icon name="graduation-hat-02" class="text-gray-500"></app-svg-icon>
    <span class="text-gray-700 text-sm leading-sm">Knowledge base</span>
  </a>

  <a href="https://step.dev/" target="_blank" mat-menu-item class="!flex items-center gap-3">
    <app-svg-icon name="info-circle" class="text-gray-500"></app-svg-icon>
    <span class="text-gray-700 text-sm leading-sm">About Step</span>
  </a>

  <a href="https://step.dev/support/" target="_blank" mat-menu-item class="!flex items-center gap-3">
    <app-svg-icon name="headphones-01" class="text-gray-500"></app-svg-icon>
    <span class="text-gray-700 text-sm leading-sm">Contact</span>
  </a>
</mat-menu>
