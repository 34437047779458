import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AccountService } from 'src/app/_services/account.service';
import { CountriesService, Country } from 'src/app/_services/countries.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { AddressSettingsComponent, AddressSettingsFormValue } from '../../address-settings/address-settings.component';

const ORGANIZATION_ADDRESS_UPDATE_SUCCESS = 'Organization address updated successfully';

@Component({
  selector: 'app-organization-address-settings',
  templateUrl: './organization-address-settings.component.html',
  styleUrls: ['./organization-address-settings.component.scss'],
})
export class OrganizationAddressSettingsComponent implements OnInit {
  @ViewChild(AddressSettingsComponent) addressSettingsComponent: AddressSettingsComponent;

  countries: Country[] = [];
  loading: boolean = false;
  initialFormValue?: AddressSettingsFormValue;

  constructor(
    private accountService: AccountService,
    private countriesService: CountriesService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    forkJoin([this.countriesService.getCountries(), this.accountService.getAddress(), this.accountService.getOrganization()]).subscribe({
      next: ([countries, organizationAddress, organization]) => {
        const country = countries.find((country) => country.name === organizationAddress.country);

        this.initialFormValue = {
          street: organizationAddress.street,
          city: organizationAddress.city,
          postalCode: organizationAddress.postalCode,
          state: organizationAddress.state,
          country: country ?? null,
          phoneNumber: organization?.phoneNumber,
        };
        this.countries = countries;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  onSubmit(formValue: AddressSettingsFormValue): void {
    this.spinnerService.show();

    const { street, city, postalCode, state, country, phoneNumber } = formValue;

    const addressData = {
      street,
      city,
      postalCode,
      state,
      country,
      phoneNumber,
    };

    this.accountService.updateOrganization(addressData).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.toastrService.success(ORGANIZATION_ADDRESS_UPDATE_SUCCESS);
        this.initialFormValue = addressData;
        this.addressSettingsComponent.onReset();
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
