import { InjectionToken } from '@angular/core';

export enum PluginType {
  ASTRA = 'controller-plugin-astra',
}

export interface Plugin {
  type: PluginType;
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
}

export const PLUGINS = new InjectionToken<Plugin[]>('plugins', {
  providedIn: 'root',
  factory: () => {
    return [
      {
        type: PluginType.ASTRA,
        imgSrc: '/assets/svg/logos/astra.logo.svg',
        imgAlt: 'Astra logo',
        title: 'Astra plugin',
        description: `Enable integration with Astra (Simplify Web UI Test Creation, Maintenance & Analysis). It requires dotnet agent for UI automation.`,
      },
    ];
  },
});
