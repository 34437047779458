import { Component, Input } from '@angular/core';
import { ClusterStatus } from '../../_models/cluster';

@Component({
  selector: 'app-cluster-status',
  templateUrl: './cluster-status.component.html',
  styleUrls: ['./cluster-status.component.scss'],
})
export class ClusterStatusComponent {
  @Input() clusterStatus!: ClusterStatus;

  readonly textByClusterStatus: Record<ClusterStatus, string> = {
    [ClusterStatus.CREATING]: 'Creating',
    [ClusterStatus.CREATION_FAILED]: 'Creation failed',
    [ClusterStatus.RUNNING]: 'Running',
    [ClusterStatus.STOPPING]: 'Stopping',
    [ClusterStatus.STOPPED]: 'Stopped',
    [ClusterStatus.STARTING]: 'Starting',
    [ClusterStatus.DESTROYING]: 'Destroying',
    [ClusterStatus.DESTROYED]: 'Destroyed',
    [ClusterStatus.ERROR]: 'Error',
    [ClusterStatus.IDLE]: 'Idle',
    [ClusterStatus.UPGRADING]: 'Upgrading',
  };
  readonly ClusterStatus = ClusterStatus;
}
