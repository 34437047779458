<button
  [type]="buttonType"
  [disabled]="disabled"
  (click)="buttonClick.emit()"
  matRipple
  class="border rounded-lg text-sm leading-sm flex items-center gap-2"
  [ngClass]="{
    'bg-primary-600 border-transparent text-white focus-ring-primary-1': buttonStyle === ButtonStyle.BTN_0,
    'bg-white border-gray-300 text-gray-700 focus-ring-primary-0': buttonStyle === ButtonStyle.BTN_1,
    'bg-white border-error-700 text-error-700 focus-ring-error-0': buttonStyle === ButtonStyle.BTN_2,
    'py-2 px-3': buttonPadding === ButtonPadding.BTN_PADDING_0,
    'w-full justify-center': fluid,
    'disabled:bg-gray-300': buttonStyle === ButtonStyle.BTN_0 && disabled,
    'disabled:bg-gray-50 disabled:border-gray-50 disabled:text-gray-300':
      (buttonStyle === ButtonStyle.BTN_1 || buttonStyle === ButtonStyle.BTN_2) && disabled
  }"
>
  <ng-content></ng-content>

  <app-spinner *ngIf="loading"></app-spinner>
</button>
