<app-dismissible-notice
  storageKey="clusterDismissibleNotice"
  icon="check-circle"
  [dismissibleNoticeStyle]="DismissibleNoticeStyle.NOTICE_0"
>
  <div class="text-sm leading-sm">
    <p class="text-primary-700 font-medium pb-1">Everything is ready to go!</p>

    <p class="text-primary-600 pb-3">Start your first cluster and dive into Step.</p>

    <a routerLink="create-cluster" class="link-0 inline-flex items-center gap-2">
      Create a cluster
      <app-svg-icon name="arrow-right"></app-svg-icon>
    </a>
  </div>
</app-dismissible-notice>
