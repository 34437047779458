import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.accountService.isAuthenticated().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.router.navigate(['login']);
          return false;
        }

        return true;
      })
    );
  }
}
