import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { switchMap, tap } from 'rxjs';
import { CardDetails } from 'src/app/_models/card-details';
import { AccountService } from 'src/app/_services/account.service';
import { BillingService } from 'src/app/_services/billing.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { CardSettingsFormSubmitParams } from '../../card-settings/card-settings.component';
import { SkeletonLoaderRounding } from '../../skeleton-loader/skeleton-loader.component';

const CARD_DETAILS_UPDATE_SUCCESS = 'Card details updated successfully';

@Component({
  selector: 'app-payment-card-settings',
  templateUrl: './payment-card-settings.component.html',
  styleUrls: ['./payment-card-settings.component.scss'],
})
export class PaymentCardSettingsComponent implements OnInit {
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  loading: boolean = false;
  editMode: boolean = false;
  cardDetails?: CardDetails;

  constructor(
    private accountService: AccountService,
    private billingService: BillingService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.billingService.getCardsDetails().subscribe({
      next: ([cardDetails]) => {
        this.cardDetails = cardDetails;
        this.editMode = !cardDetails;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  setEditMode(editMode: boolean): void {
    this.editMode = editMode;
  }

  onSubmit({ formValue, stripeInstance }: CardSettingsFormSubmitParams): void {
    if (this.spinnerService.visible) {
      return;
    }

    this.spinnerService.show();

    this.billingService
      .initPaymentSetup()
      .pipe(
        switchMap(({ clientSecret }) =>
          stripeInstance.confirmCardSetup(clientSecret, {
            payment_method: {
              card: formValue.cardNumberElement,
              billing_details: {
                email: this.accountService.userValue?.email,
                // Use the cardholder name instead of `userValue?.name` as these are billing details
                name: formValue.cardName,
              },
            },
          })
        ),
        tap(({ error }) => {
          if (error) {
            throw new Error(error.message);
          }
        }),
        switchMap(() => this.billingService.getCardsDetails())
      )
      .subscribe({
        next: ([cardDetails]) => {
          this.spinnerService.hide();
          this.toastrService.success(CARD_DETAILS_UPDATE_SUCCESS);
          this.cardDetails = cardDetails;
          this.editMode = false;
        },
        error: (error: string) => {
          this.spinnerService.hide();
          this.toastrService.error(error);
        },
      });
  }
}
