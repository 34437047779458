<app-dialog-header icon="cpu-chip-02" (dialogClose)="onCancel()"></app-dialog-header>

<app-dialog-body>
  <ng-template #heading>Request Agent Quota</ng-template>

  <ng-template #description>
    Enter the desired quota limit for your clusters. After approved by us it will become available in the agent
    selection. Note that this process may take multiple work days.
  </ng-template>

  <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
    <app-label [required]="true" [control]="formGroup.controls.requestedValue" title="New agent quota" #requestedValueLabel></app-label>
    <mat-form-field subscriptSizing="dynamic">
      <input (focusin)="requestedValueLabel.focus()" (focusout)="requestedValueLabel.focusout()" matInput placeholder="New agent quota" name="requestedValue" type="number" formControlName="requestedValue" class="relative z-[1]" />

      <mat-error *ngIf="formGroup.controls.requestedValue.hasError('required')">New agent quota is required</mat-error>
    </mat-form-field>

    <app-label [required]="true" [control]="formGroup.controls.requestedReason" title="Request reason" #requestedReasonLabel></app-label>
    <mat-form-field subscriptSizing="dynamic">
      <textarea
        (focusin)="requestedReasonLabel.focus()"
        (focusout)="requestedReasonLabel.focusout()"
        matInput
        placeholder="Request reason"
        name="requestedReason"
        rows="4"
        formControlName="requestedReason"
        class="relative z-[1]"
      ></textarea>

      <mat-error *ngIf="formGroup.controls.requestedReason.hasError('required')">Request reason is required</mat-error>
    </mat-form-field>

    <div class="text-xs leading-xs text-gray-500 italic">
      The request reason as well as the amount of requested agents will be taken into account when deciding on the
      request. It's useful to include the intent of the quota usage.
    </div>

    <div class="flex justify-center gap-3">
      <app-button
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onCancel()"
      >
        Cancel
      </app-button>

      <app-button
        [disabled]="formGroup.invalid"
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        [loading]="loading"
      >
        Request quota
      </app-button>
    </div>
  </form>
</app-dialog-body>
