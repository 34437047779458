import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { confirmPasswordValidator } from 'src/app/_helpers/validators';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';
import { AccountService } from '../../_services/account.service';

export const PASSWORD_CHANGE_SUCCESS = 'Password changed successfully';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  private terminator$ = new Subject<void>();

  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
  });

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmPassword.addValidators(confirmPasswordValidator(this.formGroup.controls.password));

    this.formGroup.controls.password.valueChanges.pipe(takeUntil(this.terminator$)).subscribe(() => {
      this.formGroup.controls.confirmPassword.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
  }

  onSubmit(): void {
    if (this.spinnerService.visible) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    const requestId = this.route.snapshot.params['id'];
    const { password } = this.formGroup.value;

    this.spinnerService.show();

    this.accountService.passwordResetVerify(requestId, password).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.toastrService.success(PASSWORD_CHANGE_SUCCESS);
        this.router.navigate(['/login']);
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
