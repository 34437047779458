<div class="inline-flex border border-gray-300 rounded-lg whitespace-nowrap">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <button
      type="button"
      (click)="activeTabIndexChange.emit(i)"
      [ngClass]="{
        '!text-primary-500': i === activeTabIndex
      }"
      class="px-3 py-2 bg-white focus:outline-none focus:bg-gray-50 hover:bg-gray-50 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg text-sm leading-sm font-medium text-gray-800"
    >
      {{ tab.title }}
    </button>

    <div *ngIf="i < tabs.length - 1" class="border-r border-gray-300"></div>
  </ng-container>
</div>

<swiper-container #swiperContainer class="w-full">
  <!-- Prevent focusing elements within inactive slides by utilizing `tabindex` -->
  <swiper-slide *ngFor="let tab of tabs; let i = index" [attr.tabindex]="i === activeTabIndex ? null : -1">
    <ng-container *ngTemplateOutlet="tab.contentTemplate"></ng-container>
  </swiper-slide>
</swiper-container>
