import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { delay, delayWhen, map, retry, retryWhen, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Billing, BillingPeriod, CustomerPrices } from '../_models/billing';
import { CardDetails } from '../_models/card-details';
import { Customer } from '../_models/customer';
import { BaseServiceService } from './base-service.service';

export enum Filter {
  All,
  Selected,
  Unselected,
}

export enum ComponentType {
  CONTROLLER_PLUGIN = 'CONTROLLER_PLUGIN',
  AGENT_USAGE = 'AGENT_USAGE',
  CONTROLLER_USAGE = 'CONTROLLER_USAGE',
}

export interface DataLine {
  quantity: number;
  price: {
    nickname: string;
    unit_amount_decimal: number;
  };
  amount: number;
}

@Injectable({
  providedIn: 'root',
})
export class BillingService extends BaseServiceService {
  public prices: Observable<CustomerPrices>;

  readonly PERIOD: Record<string, BillingPeriod> = {
    minute: {
      unit: '15 minutes',
      minutes: 15,
      decimalPlaces: '1.4-4',
    },
    hour: {
      unit: 'hour',
      minutes: 60,
      decimalPlaces: '1.2-2',
    },
    day: {
      unit: 'day',
      minutes: 60 * 24,
      decimalPlaces: '1.2-2',
    },
  };

  constructor(http: HttpClient) {
    super(http);

    this.createCachedObservable<Billing>('billing');
    this.createCachedObservable<Customer>('customer');
    this.createCachedObservable<any>('invoices');
    this.prices = this.getCustomerPrices();
  }

  public get customerValue(): Customer | undefined {
    return this.getCachedValue<Customer>('customer');
  }

  getCustomer() {
    this.http
      .get<any>(`${environment.apiUrl}/billing/customer`)
      .pipe(
        retry(10),
        map((data: any) => {
          const customer: Customer = {
            default_payment_method: data.invoice_settings?.default_payment_method,
          };
          this.updateCachedValue<Customer>('customer', customer);
          return customer;
        })
      )
      .subscribe();

    return this.getCachedObservable<Customer>('customer');
  }

  getCustomerWithPayment() {
    this.http
      .get<any>(`${environment.apiUrl}/billing/customer`)
      .pipe(
        delay(1000),
        map((data) => {
          if (!data.invoice_settings?.default_payment_method) {
            throw data;
          }
          return data;
        }),
        retryWhen((errors) =>
          errors.pipe(
            // retry with delay if no default_payment_method available
            tap((customer) =>
              console.log(`Customer has no default payment method yet, retry retrieving customer`, customer)
            ),
            delayWhen((val) => timer(1000))
          )
        ),
        map((data: any) => {
          const customer: Customer = {
            default_payment_method: data.invoice_settings?.default_payment_method,
          };
          this.updateCachedValue<Customer>('customer', customer);
          return customer;
        })
      )
      .subscribe();

    return this.getCachedObservable<Customer>('customer');
  }

  getInvoices(): Observable<Billing[]> {
    this.http
      .get<any>(`${environment.apiUrl}/billing/invoices`)
      .pipe(
        map((invoiceCollection: any) => invoiceCollection.data),
        map((invoiceArray: any) => invoiceArray.filter((invoice: any) => invoice.total > 0)),
        map((invoiceArray: any) => invoiceArray.map(this._invoiceToBilling))
      )
      .subscribe((invoices) => this.updateCachedValue<any>('invoices', invoices));

    return this.getCachedObservable<Billing[]>('invoices');
  }

  getBilling(): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/billing/invoices/invoice-preview`)
      .pipe(map(this._invoiceToBilling));
  }

  refreshBilling() {
    this.http
      .get<any>(`${environment.apiUrl}/billing/invoices/invoice-preview`)
      .pipe(
        retry(10),
        map(this._invoiceToBilling),
        map((billing: Billing) => this.updateCachedValue<Billing>('billing', billing))
      )
      .subscribe();
  }

  // this should only be called ONCE before a payment is set up
  initPaymentSetup() {
    return this.http
      .get<{ clientSecret: string; setupIntentId: string }>(`${environment.apiUrl}/billing/customer/init-payment-setup`)
      .pipe(
        map((data: { clientSecret: string; setupIntentId: string }) => {
          console.log('init-payment-setup', data);
          return data;
        })
      );
  }

  getStripeKey() {
    return this.http.get<{ publishableKey: string }>(`${environment.apiUrl}/billing/config`);
  }

  /*
    Returns the current prices which will apply to new customers
   */
  getPrices() {
    return this.http.get<any>(`${environment.apiUrl}/billing/prices`);
  }

  getCardsDetails(): Observable<CardDetails[]> {
    return this.http.get<CardDetails[]>(`${environment.apiUrl}/billing/customer/cards-details`).pipe(
      map((cardsDetails) => {
        return cardsDetails.map(({ brand, last4, expMonth, expYear }) => {
          return new CardDetails(brand, last4, expMonth, expYear);
        });
      })
    );
  }

  /*
    Returns the prices that apply to the logged in customer (can differ from getPrices!)
   */
  getCustomerPrices(): Observable<CustomerPrices> {
    return this.http.get<CustomerPrices>(`${environment.apiUrl}/billing/customer/prices`);
  }

  _invoiceToBilling(invoice: any) {
    const billing: Billing = invoice;
    billing.balance = invoice.total + invoice.starting_balance;

    billing.lines.data = billing.lines.data.filter(item => item.quantity && item.quantity > 0);

    if (invoice.default_tax_rates[0]) {
      // only show VAT if there is a tax_rate associated with the invoice
      billing.lines.data.push({
        amount: invoice.total_tax_amounts[0]?.amount,
        currency: 'chf',
        price: { nickname: 'VAT (' + invoice.default_tax_rates[0]?.percentage + '%)' },
      });
    }

    billing.lines.data.push({
      amount: invoice.total,
      currency: 'chf',
      price: { nickname: 'Total' },
    });

    if (billing.balance < 0) {
      billing.lines.data.push({
        amount: -invoice.total,
        currency: 'chf',
        price: { nickname: 'Applied Credits' },
      });
      billing.lines.data.push({
        amount: 0,
        currency: 'chf',
        price: { nickname: 'Amount Due' },
      });
    } else if (invoice.starting_balance < 0) {
      billing.lines.data.push({
        amount: -invoice.starting_balance,
        currency: 'chf',
        price: { nickname: 'Applied Credits' },
      });
      billing.lines.data.push({
        amount: billing.balance,
        currency: 'chf',
        price: { nickname: 'Amount Due' },
      });
    }

    return billing;
  }

  /*
   *  Returns the available quota of agents allowed
   */
  getQuota(): Observable<number> {
    return this.http
      .get<{ AGENTS_PER_CLUSTER: number }>(`${environment.apiUrl}/organization/quotas`)
      .pipe(map(({ AGENTS_PER_CLUSTER }) => AGENTS_PER_CLUSTER));
  }
}
