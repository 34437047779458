import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../_dialog/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private matDialog: MatDialog) {}

  confirm(dialogData: ConfirmationDialogData): Observable<boolean> {
    return this.matDialog.open(ConfirmationDialogComponent, { data: dialogData }).afterClosed() as Observable<boolean>;
  }
}
