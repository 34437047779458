<h3 class="text-lg leading-lg font-medium text-gray-900 pb-1">Members</h3>

<p class="text-sm leading-sm text-gray-500">Manage organization members</p>

<div class="pt-4 pb-6">
  <hr />
</div>

<app-skeleton-loader
  [loading]="loading"
  [rounding]="SkeletonLoaderRounding.ROUNDED_LG"
  class="block px-1"
  [ngClass]="{
    'rounded-lg overflow-hidden': loading
  }"
>
  <table mat-table [dataSource]="users" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

      <td mat-cell *matCellDef="let user">
        <div class="flex gap-3">
          <div class="p-3 rounded-full bg-gray-50 text-gray-600">
            <app-svg-icon name="user-01"></app-svg-icon>
          </div>

          <div class="flex-1 text-sm leading-sm">
            <div class="text-gray-900 font-medium">{{ user.fullName }}</div>
            <div class="text-gray-500">{{ user.email }}</div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>

      <td mat-cell *matCellDef="let user">
        <div class="text-sm leading-sm text-gray-500">
          {{ user.organizationOwner ? "Owner" : "Admin" }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <div class="flex gap-3 text-sm leading-sm">
          <button
            type="button"
            (click)="onDelete(user)"
            class="text-gray-500 font-medium focus:outline-none focus:text-primary-500"
          >
            Delete
          </button>

          <button
            type="button"
            (click)="onEdit(user)"
            class="text-primary-700 font-medium focus:outline-none focus:text-primary-500"
          >
            Edit
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
  </table>
</app-skeleton-loader>

<div class="py-5">
  <hr />
</div>

<div class="pr-1 pb-1 flex justify-end">
  <app-button
    [buttonType]="ButtonType.BUTTON"
    [buttonStyle]="ButtonStyle.BTN_0"
    [buttonPadding]="ButtonPadding.BTN_PADDING_0"
    (buttonClick)="onAddMember()"
  >
    Add organization member
  </app-button>
</div>

<ng-template #headingTemplate>Are you sure?</ng-template>
<ng-template #descriptionTemplate let-user>
  You are about to delete <strong>{{ user.email }}</strong> from your organization. Do you wish to continue?
</ng-template>
