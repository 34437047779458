import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ButtonStyle {
  BTN_0,
  BTN_1,
  BTN_2,
}

export enum ButtonPadding {
  BTN_PADDING_0,
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonType!: ButtonType;
  @Input() buttonStyle!: ButtonStyle;
  @Input() buttonPadding!: ButtonPadding;
  @Input() disabled?: boolean;
  @Input() fluid?: boolean;
  @Input() loading?: boolean;

  @Output() buttonClick = new EventEmitter<void>();

  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
}
