<app-dialog-header icon="plus-circle" (dialogClose)="onCancel()"></app-dialog-header>

<ng-container *ngIf="registrationState$ | async as registrationState">
  <app-dialog-body>
    <ng-template #heading>Create a new Step cluster</ng-template>
    <ng-template #description>
      <ng-container [ngSwitch]="registrationState">
        <ng-container *ngSwitchCase="RegistrationState.ON_HOLD">
          It looks like there is an issue with your payment method. Please
          <a href="https://step.dev/contact/" target="_blank" class="link-0">contact us</a> so the issue can be
          resolved and you can continue using Step!
        </ng-container>

        <ng-container *ngSwitchDefault>
          Great! It looks like you already have free credits. Just add a payment method to start using it. We won't bill
          you as long as you don't use more than the free credits, promised.
        </ng-container>
      </ng-container>
    </ng-template>

    <div class="flex justify-center gap-3">
      <app-button
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onCancel()"
      >
        Cancel
      </app-button>

      <app-button
        [disabled]="registrationState === RegistrationState.ON_HOLD"
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onAddPayment()"
      >
        Add payment method
      </app-button>
    </div>
  </app-dialog-body>
</ng-container>
