<div class="flex flex-col items-center gap-2">
  <button
    type="button"
    (click)="onStepClick()"
    [ngClass]="{
      'bg-primary-100': active,
      'bg-primary-900': !active,
      'transition-all duration-300': paginationTransition
    }"
    class="border-2 border-primary-100 rounded-full p-2 focus-ring-primary-2"
  >
    <div
      *ngIf="!complete"
      [ngClass]="{
        'bg-primary-600': active,
        'bg-primary-100': !active,
        'transition-all duration-300': paginationTransition
      }"
      class="w-2.5 h-2.5 rounded-full"
    ></div>

    <app-svg-icon
      *ngIf="complete"
      name="tick"
      [ngClass]="{
        'text-primary-600': active,
        'text-primary-100': !active,
        'transition-all duration-300': paginationTransition
      }"
      class="text-sm"
    ></app-svg-icon>
  </button>

  <div *ngIf="hasNext" class="h-12 border-r-2 border-primary-100 rounded-sm"></div>
</div>

<div class="pt-[0.1875rem]">
  <div class="text-white font-medium">{{ title }}</div>
  <div class="text-primary-200">{{ description }}</div>
</div>
