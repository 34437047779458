<app-dialog-header icon="mail-01" (dialogClose)="matDialogRef.close()"></app-dialog-header>

<app-dialog-body>
  <ng-template #heading>Check your your inbox</ng-template>
  <ng-template #description>
    We sent the confirmation link to your email address. If you didn't receive the email make sure to also check your
    spam folder.
  </ng-template>

  <app-button
    [buttonType]="ButtonType.BUTTON"
    [buttonStyle]="ButtonStyle.BTN_0"
    [buttonPadding]="ButtonPadding.BTN_PADDING_0"
    [fluid]="true"
    [loading]="loading"
    (buttonClick)="onContinue()"
  >
    Continue
  </app-button>
</app-dialog-body>
