<div class="flex justify-between pb-8">
  <h1 class="text-3xl leading-3xl font-medium text-gray-900">My clusters</h1>

  <a
    routerLink="create-cluster"
    matRipple
    class="border rounded-lg text-sm leading-sm flex items-center gap-2 bg-primary-600 border-transparent text-white focus-ring-primary-1 disabled:bg-gray-300 py-2 px-3"
  >
    <app-svg-icon name="plus"></app-svg-icon>
    Create a cluster
  </a>
</div>

<app-skeleton-loader
  *ngIf="clustersExist"
  [loading]="loading"
  [rounding]="SkeletonLoaderRounding.ROUNDED_LG"
  [ngClass]="{
    'block rounded-lg overflow-hidden': loading
  }"
>
  <table mat-table [dataSource]="clustersDataSource" matSort (matSortChange)="onSortChange($event)" class="with-footer">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

      <td mat-cell *matCellDef="let cluster">
        <div class="text-sm leading-sm font-medium text-gray-900">
          {{ cluster.name }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div class="flex items-center gap-1">
          Status
          <app-svg-icon name="help-circle" matTooltip="Running clusters are billable until stopped"></app-svg-icon>
        </div>
      </th>

      <td mat-cell *matCellDef="let cluster">
        <app-cluster-status [clusterStatus]="cluster.state"></app-cluster-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>

      <td mat-cell *matCellDef="let cluster">
        <div class="flex gap-4 text-gray-500 text-lg">
          <button
            type="button"
            [disabled]="statusChangeOngoingByCluster[cluster.id] || cluster.state !== ClusterStatus.STOPPED"
            matRipple
            matTooltip="Start cluster"
            (click)="startCluster(cluster)"
            class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full"
          >
            <app-svg-icon name="play-circle"></app-svg-icon>
          </button>

          <button
            type="button"
            [disabled]="statusChangeOngoingByCluster[cluster.id] || cluster.state !== ClusterStatus.RUNNING"
            matRipple
            matTooltip="Stop cluster"
            (click)="stopCluster(cluster)"
            class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full"
          >
            <app-svg-icon name="stop-circle"></app-svg-icon>
          </button>

          <a
            [routerLink]="['details', cluster.id]"
            matRipple
            matTooltip="Edit cluster"
            class="focus:outline-none focus:text-primary-500 p-2 rounded-full"
          >
            <app-svg-icon name="settings-01"></app-svg-icon>
          </a>

          <a
            [href]="cluster.url"
            target="_blank"
            [attr.tabindex]="cluster.state !== ClusterStatus.RUNNING ? -1 : null"
            matRipple
            matTooltip="Go to STEP instance"
            [ngClass]="{
              'pointer-events-none text-gray-300': cluster.state !== ClusterStatus.RUNNING
            }"
            class="focus:outline-none focus:text-primary-500 p-2 rounded-full"
          >
            <app-svg-icon name="link-external-01"></app-svg-icon>
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="clustersTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: clustersTableColumns"></tr>

    <ng-container matColumnDef="footer">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="clustersTableColumns.length">
        <div class="w-full h-[4.5rem] flex items-center justify-between">
          <app-button
            [buttonType]="ButtonType.BUTTON"
            [buttonStyle]="ButtonStyle.BTN_1"
            [buttonPadding]="ButtonPadding.BTN_PADDING_0"
            [disabled]="paginator.pageIndex === 0"
            (buttonClick)="paginator.previousPage()"
          >
            Previous
          </app-button>

          <div class="text-sm leading-sm font-medium text-gray-700">
            Page {{ paginator.pageIndex + 1 }} of {{ paginator.length / paginator.pageSize | mathCeiling }}
          </div>

          <app-button
            [buttonType]="ButtonType.BUTTON"
            [buttonStyle]="ButtonStyle.BTN_1"
            [buttonPadding]="ButtonPadding.BTN_PADDING_0"
            [disabled]="paginator.pageIndex === (paginator.length / paginator.pageSize | mathCeiling) - 1"
            (buttonClick)="paginator.nextPage()"
          >
            Next
          </app-button>
        </div>
      </td>
    </ng-container>

    <tr mat-footer-row *matFooterRowDef="['footer']"></tr>
  </table>

  <mat-paginator #paginator [pageSizeOptions]="[10]"></mat-paginator>
</app-skeleton-loader>

<app-cluster-dismissible-notice *ngIf="!clustersExist"></app-cluster-dismissible-notice>
