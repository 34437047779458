import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AddPaymentWizardDialogComponent } from 'src/app/_dialog/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { RegistrationState } from 'src/app/_models/registration-state.enum';
import { AccountService } from 'src/app/_services/account.service';
import { SidebarLayoutComponent } from '../sidebar-layout/sidebar-layout.component';
import { PaymentCardSettingsComponent } from './payment-card-settings/payment-card-settings.component';

export enum SettingsTabs {
  USER_SETTINGS,
  MANAGE_ORGANIZATION,
  PAYMENT_METHOD,
}

export const SETTINGS_DEFAULT_ACTIVE_TAB_INDEX = SettingsTabs.USER_SETTINGS;
export const SETTINGS_ACTIVE_TAB_QUERY_PARAM = 'activeTab';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild(PaymentCardSettingsComponent) paymentCardSettingsComponent: PaymentCardSettingsComponent;
  // @ViewChild(PaymentAddressSettingsComponent) paymentAddressSettingsComponent: PaymentAddressSettingsComponent;

  private terminator$ = new Subject<void>();

  activeTabIndex: number = SETTINGS_DEFAULT_ACTIVE_TAB_INDEX;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sidebarLayoutComponent: SidebarLayoutComponent,
    private accountService: AccountService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.terminator$)).subscribe((queryParams) => {
      const activeTabQueryParam = queryParams[SETTINGS_ACTIVE_TAB_QUERY_PARAM];

      // Determine active tab index, if none fallback to default
      this.activeTabIndex = activeTabQueryParam ? +activeTabQueryParam : SETTINGS_DEFAULT_ACTIVE_TAB_INDEX;

      // Reset scrolling position (UX)
      this.sidebarLayoutComponent.scrollableContainer.nativeElement.scrollTop = 0;

      // No need to navigate in case `activeTabQueryParam` as we are already there
      if (!activeTabQueryParam) {
        this.navigateTo(this.activeTabIndex);
      }
    });
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
  }

  onActiveTabIndexChange(activeTabIndex: number): void {
    // Show add payment wizard if there is no payment method set up
    if (
      activeTabIndex === SettingsTabs.PAYMENT_METHOD &&
      this.accountService.organizationValue?.registrationState !== RegistrationState.BILLABLE
    ) {
      const dialogRef = this.matDialog.open(AddPaymentWizardDialogComponent, {
        // See `mat-dialog.scss`
        panelClass: 'dialog-full-screen',
      });

      dialogRef.afterClosed().subscribe((wizardCompleted) => {
        if (!wizardCompleted) {
          return;
        }

        this.navigateTo(activeTabIndex);
        // Trigger refresh manually for payment settings components
        this.paymentCardSettingsComponent.ngOnInit();
        // this.paymentAddressSettingsComponent.ngOnInit();
      });

      return;
    }

    this.navigateTo(activeTabIndex);
  }

  private navigateTo(activeTabIndex: number): void {
    this.router.navigate([], {
      queryParams: {
        [SETTINGS_ACTIVE_TAB_QUERY_PARAM]: activeTabIndex,
      },
      replaceUrl: this.router.url === '/settings',
    });
  }
}
