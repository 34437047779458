<h3 class="text-lg leading-lg font-medium text-gray-900">Personal info</h3>

<div class="pt-4 pb-6">
  <hr />
</div>

<form [formGroup]="formGroup">
  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Organization</label>

    <mat-form-field subscriptSizing="dynamic" class="flex-[4]">
      <input placeholder="Organization" type="text" name="organizationName" matInput formControlName="organizationName" class="relative z-[1]" />
    </mat-form-field>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Email address</label>

    <mat-form-field subscriptSizing="dynamic" class="flex-[4]">
      <app-svg-icon name="mail-01" matPrefix></app-svg-icon>

      <input placeholder="Email address" type="text" name="email" autocomplete="email" matInput formControlName="email" class="relative z-[1]" />
    </mat-form-field>

    <div class="flex-[1]"></div>
  </div>
</form>
