import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-visibility',
  templateUrl: './password-visibility.component.html',
  styleUrls: ['./password-visibility.component.scss'],
})
export class PasswordVisibilityComponent implements OnInit {
  @Input() inputElement!: HTMLInputElement;

  passwordVisible: boolean = false;

  ngOnInit(): void {
    this.passwordVisible = this.inputElement.type !== 'password';
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;

    this.inputElement.type = this.passwordVisible ? 'text' : 'password';
  }

  onMouseDown(event: MouseEvent): void {
    // Prevent toggling visibility from blurring out the input thus making it touched
    event.preventDefault();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.code !== 'Enter' && event.code !== 'Space') {
      return;
    }

    // Prevent Enter/Space from focusing the input
    event.preventDefault();
    // Manually toggle visibility
    this.togglePasswordVisibility();
  }
}
