<form
  *ngIf="stripe$ | async as stripe"
  [formGroup]="formGroup"
  ngxStripeCardGroup
  [stripe]="stripe"
  [elementsOptions]="elementsOptions"
  (submit)="onSubmit(stripe)"
>
  <div class="flex">
    <div class="flex-[2]">
      <label class="text-sm leading-sm text-gray-700 font-medium">Card details</label>
    </div>

    <div class="flex-[4] flex flex-col gap-4">
      <div class="flex gap-4">
        <div class="flex items-end relative">
          <app-label [required]="true" [control]="formGroup.controls.cardName" title="Name on card" #cardNameLabel></app-label>
          <mat-form-field subscriptSizing="dynamic" class="flex-[3]">
            <input (focusin)="cardNameLabel.focus()" (focusout)="cardNameLabel.focusout()" placeholder="Name on card" type="text" name="cardName" autocomplete="cc-name" matInput formControlName="cardName" class="relative z-[1]" />

            <mat-error *ngIf="formGroup.controls.cardName.hasError('required')">Name on card is required</mat-error>
          </mat-form-field>
        </div>

        <app-stripe-card-input-wrapper
          label="Expiry"
          [required]="true"
          [error]="
            formGroup.controls.cardExpiry.errors
              ? formGroup.controls.cardExpiry.errors['required'] ||
                formGroup.controls.cardExpiry.errors['stripeValidation']
              : ''
          "
          [attr.data-invalid]="
            formGroup.controls.cardExpiry.errors
              ? !!formGroup.controls.cardExpiry.errors['required'] ||
                !!formGroup.controls.cardExpiry.errors['stripeValidation']
              : false
          "
          class="flex-[1] group"
        >
          <ngx-stripe-card-expiry
            [options]="cardExpiryOptions"
            (blur)="onBlur(formGroup.controls.cardExpiry)"
            (change)="onChange(formGroup.controls.cardExpiry, $event)"
          ></ngx-stripe-card-expiry>
        </app-stripe-card-input-wrapper>
      </div>

      <div class="flex gap-4">
        <app-stripe-card-input-wrapper
          label="Card number"
          [required]="true"
          [error]="
            formGroup.controls.cardNumber.errors
              ? formGroup.controls.cardNumber.errors['required'] ||
                formGroup.controls.cardNumber.errors['stripeValidation']
              : ''
          "
          [attr.data-invalid]="
            formGroup.controls.cardNumber.errors
              ? !!formGroup.controls.cardNumber.errors['required'] ||
                !!formGroup.controls.cardNumber.errors['stripeValidation']
              : false
          "
          class="flex-[3] group"
        >
          <ngx-stripe-card-number
            [options]="cardNumberOptions"
            (blur)="onBlur(formGroup.controls.cardNumber)"
            (change)="onChange(formGroup.controls.cardNumber, $event)"
          ></ngx-stripe-card-number>
        </app-stripe-card-input-wrapper>

        <app-stripe-card-input-wrapper
          label="CVC"
          [required]="true"
          [error]="
            formGroup.controls.cardCSC.errors
              ? formGroup.controls.cardCSC.errors['required'] || formGroup.controls.cardCSC.errors['stripeValidation']
              : ''
          "
          [attr.data-invalid]="
            formGroup.controls.cardCSC.errors
              ? !!formGroup.controls.cardCSC.errors['required'] ||
                !!formGroup.controls.cardCSC.errors['stripeValidation']
              : false
          "
          class="flex-[1] group"
        >
          <ngx-stripe-card-cvc
            [options]="cardCVCOptions"
            (blur)="onBlur(formGroup.controls.cardCSC)"
            (change)="onChange(formGroup.controls.cardCSC, $event)"
          ></ngx-stripe-card-cvc>
        </app-stripe-card-input-wrapper>
      </div>
    </div>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="pr-1 pb-1 flex justify-end">
    <div class="flex gap-3">
      <app-button
        *ngIf="withCancelButton"
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="cancelClick.emit()"
      >
        Cancel
      </app-button>

      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      >
        {{ submitButtonTitle }}
      </app-button>
    </div>
  </div>
</form>
