<app-dialog-header icon="help-circle" (dialogClose)="onCancel()"></app-dialog-header>

<app-dialog-body>
  <ng-template #heading>
    <ng-container *ngIf="dialogData.headingTemplate">
      <ng-container
        *ngTemplateOutlet="
          dialogData.headingTemplate;
          context: dialogData.headingContext ? dialogData.headingContext : {}
        "
      ></ng-container>
    </ng-container>
  </ng-template>

  <ng-template #description>
    <ng-container *ngIf="dialogData.descriptionTemplate">
      <ng-container
        *ngTemplateOutlet="
          dialogData.descriptionTemplate;
          context: dialogData.descriptionContext ? dialogData.descriptionContext : {}
        "
      ></ng-container>
    </ng-container>
  </ng-template>

  <div class="flex justify-center gap-3">
    <app-button
      [buttonType]="ButtonType.BUTTON"
      [buttonStyle]="ButtonStyle.BTN_1"
      [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      (buttonClick)="onCancel()"
    >
      Cancel
    </app-button>

    <app-button
      [buttonType]="ButtonType.BUTTON"
      [buttonStyle]="ButtonStyle.BTN_0"
      [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      (buttonClick)="onContinue()"
    >
      Continue
    </app-button>
  </div>
</app-dialog-body>
