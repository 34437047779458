<app-dismissible-notice
  storageKey="stripeDismissibleNotice"
  icon="shield-dollar"
  [dismissibleNoticeStyle]="DismissibleNoticeStyle.NOTICE_0"
>
  <div class="text-sm leading-sm">
    <p class="text-primary-700 font-medium pb-1">Your credit card will be stored and managed securely by Stripe.</p>

    <p class="text-primary-600 pb-3">We will never receive or have access to your full credit card information.</p>

    <a href="https://stripe.com/docs/security" target="_blank" class="link-0 inline-flex items-center gap-2">
      Learn more about security at Stripe
      <app-svg-icon name="arrow-right"></app-svg-icon>
    </a>
  </div>
</app-dismissible-notice>
