import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { confirmPasswordValidator } from 'src/app/_helpers/validators';
import { AccountService } from 'src/app/_services/account.service';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { ButtonPadding, ButtonStyle, ButtonType } from '../../button/button.component';
import { SkeletonLoaderRounding } from '../../skeleton-loader/skeleton-loader.component';

const CHANGE_PASSWORD_SUCCESS = 'Password updated successfully';

@Component({
  selector: 'app-user-change-password-settings',
  templateUrl: './user-change-password-settings.component.html',
  styleUrls: ['./user-change-password-settings.component.scss'],
})
export class UserChangePasswordSettingsComponent implements OnInit, OnDestroy {
  private terminator$ = new Subject<void>();

  readonly formGroup = this.formBuilder.group({
    currentPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(8)]],
    confirmNewPassword: ['', [Validators.required, Validators.minLength(8)]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private spinnerService: SpinnerService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmNewPassword.addValidators(
      confirmPasswordValidator(this.formGroup.controls.newPassword)
    );

    this.formGroup.controls.newPassword.valueChanges.pipe(takeUntil(this.terminator$)).subscribe(() => {
      this.formGroup.controls.confirmNewPassword.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
  }

  onReset(): void {
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const { currentPassword, newPassword } = this.formGroup.value;

    this.spinnerService.show();

    this.accountService.changeUserPassword(this.accountService.userValue!.id, currentPassword, newPassword).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.toastrService.success(CHANGE_PASSWORD_SUCCESS);
        this.onReset();
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
