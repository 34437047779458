import { Component, Input } from '@angular/core';

export enum SkeletonLoaderRounding {
  ROUNDED_MD,
  ROUNDED_LG,
}

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() loading?: boolean;
  @Input() rounding?: SkeletonLoaderRounding;
  @Input() withBorder?: boolean;
  @Input() borderWidth?: string;

  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;
}
