<button
  type="button"
  (click)="togglePasswordVisibility()"
  (keydown)="onKeyDown($event)"
  (mousedown)="onMouseDown($event)"
  class="flex items-center focus:outline-none focus:text-primary-500"
>
  <app-svg-icon *ngIf="!passwordVisible" name="eye"></app-svg-icon>

  <app-svg-icon *ngIf="passwordVisible" name="eye-off"></app-svg-icon>
</button>
