<h3 class="text-lg leading-lg font-medium text-gray-900 pb-1">Account</h3>

<p class="text-sm leading-sm text-gray-500">Danger zone - you can delete your account from here</p>

<div class="pt-4 pb-6">
  <hr />
</div>

<div class="pl-1 pb-1">
  <app-button
    [buttonType]="ButtonType.BUTTON"
    [buttonStyle]="ButtonStyle.BTN_2"
    [buttonPadding]="ButtonPadding.BTN_PADDING_0"
    (buttonClick)="onCloseAccount()"
  >
    Close account
  </app-button>
</div>
