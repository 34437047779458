<div
  *ngIf="active"
  [@zoomOutDownOnLeave]
  [ngClass]="{
    'bg-primary-25 border-primary-300': dismissibleNoticeStyle === DismissibleNoticeStyle.NOTICE_0,
    'bg-gray-25 border-gray-300': dismissibleNoticeStyle === DismissibleNoticeStyle.NOTICE_1
  }"
  class="p-4 flex gap-3 rounded-lg border mb-6"
>
  <div
    [ngClass]="{
      'text-primary-600': dismissibleNoticeStyle === DismissibleNoticeStyle.NOTICE_0,
      'text-gray-600': dismissibleNoticeStyle === DismissibleNoticeStyle.NOTICE_1
    }"
    class="h-5 flex items-center"
  >
    <app-svg-icon [name]="icon"></app-svg-icon>
  </div>

  <div class="flex-1">
    <ng-content></ng-content>
  </div>

  <button
    type="button"
    [ngClass]="{
      'text-primary-500': dismissibleNoticeStyle === DismissibleNoticeStyle.NOTICE_0,
      'text-gray-500': dismissibleNoticeStyle === DismissibleNoticeStyle.NOTICE_1
    }"
    (click)="onDismiss()"
    class="self-start p-2 flex items-center text-xs rounded-full relative -top-1 -right-1 focus-ring-primary-1"
  >
    <app-svg-icon name="x-close"></app-svg-icon>
  </button>
</div>
