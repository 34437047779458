<h3 class="text-lg leading-lg font-medium text-gray-900">Change password</h3>

<div class="pt-4 pb-6">
  <hr />
</div>

<form [formGroup]="formGroup" (submit)="onSubmit()">
  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Current password *</label>

    <mat-form-field subscriptSizing="dynamic" class="with-suffix flex-[4]">
      <input
        #currentPasswordInput
        matInput
        placeholder="Current password"
        name="currentPassword"
        type="password"
        autocomplete="current-password"
        formControlName="currentPassword"
        class="relative z-[1]"
      />

      <app-password-visibility [inputElement]="currentPasswordInput" matSuffix></app-password-visibility>

      <mat-error *ngIf="formGroup.controls.currentPassword.hasError('required')">
        Current password is required
      </mat-error>
    </mat-form-field>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">New password *</label>

    <mat-form-field subscriptSizing="dynamic" class="with-suffix flex-[4]">
      <input
        #newPasswordInput
        matInput
        placeholder="New password"
        name="newPassword"
        type="password"
        autocomplete="new-password"
        formControlName="newPassword"
        class="relative z-[1]"
      />

      <app-password-visibility [inputElement]="newPasswordInput" matSuffix></app-password-visibility>

      <mat-hint>
        A secure password requires 8 letters that include: special characters, numbers, small and capital letters
      </mat-hint>
      <mat-error *ngIf="formGroup.controls.newPassword.hasError('required')">New password is required</mat-error>
      <mat-error *ngIf="formGroup.controls.newPassword.hasError('minlength')"> New password is too short </mat-error>
    </mat-form-field>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Confirm new password *</label>

    <mat-form-field subscriptSizing="dynamic" class="with-suffix flex-[4]">
      <input
        #confirmNewPasswordInput
        matInput
        placeholder="Confirm new password"
        name="confirmNewPassword"
        type="password"
        formControlName="confirmNewPassword"
        class="relative z-[1]"
      />

      <app-password-visibility [inputElement]="confirmNewPasswordInput" matSuffix></app-password-visibility>

      <mat-error *ngIf="formGroup.controls.confirmNewPassword.hasError('required')">
        Confirm new password is required
      </mat-error>
      <mat-error *ngIf="formGroup.controls.confirmNewPassword.hasError('minlength')">
        Confirm password is too short
      </mat-error>
      <mat-error *ngIf="formGroup.controls.confirmNewPassword.hasError('confirmPassword')">
        Passwords do not match
      </mat-error>
    </mat-form-field>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="pr-1 pb-1 flex justify-end">
    <div class="flex gap-3">
      <app-button
        *ngIf="formGroup.dirty"
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onReset()"
      >
        Cancel
      </app-button>

      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      >
        Update password
      </app-button>
    </div>
  </div>
</form>
