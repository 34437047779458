import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BillingPeriod, BillingPeriodType, CustomerPrices } from 'src/app/_models/billing';
import { BillingService } from 'src/app/_services/billing.service';
import { AgentsControlValue } from '../agents-configuration/agents-configuration.component';
import { PluginTypesControlValue } from '../plugins-configuration/plugins-configuration.component';

@Component({
  selector: 'app-costs-switcher',
  templateUrl: './costs-switcher.component.html',
  styleUrls: ['./costs-switcher.component.scss'],
})
export class CostsSwitcherComponent {
  @Input() customerPrices?: CustomerPrices;
  @Input() agentsControlValue!: AgentsControlValue;
  @Input() pluginTypesControlValue!: PluginTypesControlValue;
  @Input() billingPeriodTypeControl = new UntypedFormControl(BillingPeriodType.HOUR);

  readonly billingPeriod = this.billingService.PERIOD;
  readonly billingPeriodsEntries = Object.entries(this.billingService.PERIOD).map(
    ([billingPeriodType, billingPeriod]) =>
      ({
        billingPeriodType,
        billingPeriod,
      } as {
        billingPeriodType: BillingPeriodType;
        billingPeriod: BillingPeriod;
      })
  );

  currentBillingPeriod = this.billingPeriod[BillingPeriodType.HOUR];

  constructor(private billingService: BillingService) {}

  onChangeBillingPeriodType(billingPeriod: BillingPeriod, billingPeriodType: BillingPeriodType): void {
    this.currentBillingPeriod = billingPeriod;
    this.billingPeriodTypeControl.setValue(billingPeriodType);
  }
}
