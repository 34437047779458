import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { confirmPasswordValidator } from 'src/app/_helpers/validators';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';

export interface EditUserDialogData {
  user: User;
}

export interface EditUserDialogFormValue {
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserDialogComponent implements OnInit, OnDestroy {
  private terminator$ = new Subject<void>();

  readonly formGroup = this.formBuilder.group({
    organizationName: [''],
    email: [''],
    currentPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(8)]],
    confirmNewPassword: ['', [Validators.required, Validators.minLength(8)]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;
  disableClose: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    public matDialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: EditUserDialogData
  ) {}

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmNewPassword.addValidators(
      confirmPasswordValidator(this.formGroup.controls.newPassword)
    );

    this.formGroup.controls.newPassword.valueChanges.pipe(takeUntil(this.terminator$)).subscribe(() => {
      this.formGroup.controls.confirmNewPassword.updateValueAndValidity();
    });

    this.formGroup.controls.organizationName.setValue(this.accountService.organizationValue?.name);
    this.formGroup.controls.email.setValue(this.accountService.userValue?.email);
    this.formGroup.controls.organizationName.disable();
    this.formGroup.controls.email.disable();
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
  }

  onCancel(): void {
    if (this.disableClose) {
      return;
    }

    this.matDialogRef.close(false);
  }

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    this.disableClose = true;
    this.matDialogRef.disableClose = true;

    const { currentPassword, newPassword } = this.formGroup.value;

    this.accountService.changeUserPassword(this.dialogData.user.id, currentPassword, newPassword).subscribe({
      next: (users) => {
        this.matDialogRef.close(true);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.disableClose = false;
        this.matDialogRef.disableClose = false;
      },
    });
  }
}
