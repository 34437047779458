import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Cluster, ClusterStatus } from '../_models/cluster';
import { RegistrationState } from '../_models/registration-state.enum';
import { User } from '../_models/user';

// array in local storage for registered users
let users = JSON.parse(<string>localStorage.getItem('users')) || [];
let clusters = JSON.parse(<string>localStorage.getItem('clusters')) || [];
let clusterPasswords = JSON.parse(<string>localStorage.getItem('cluster-passwords')) || {};
let session = JSON.parse(<string>localStorage.getItem('session')) || {};
let billingList = JSON.parse(<string>localStorage.getItem('billingList')) || [];
let customerList = JSON.parse(<string>localStorage.getItem('customerList')) || [];
let organizations = JSON.parse(<string>localStorage.getItem('organizations')) || [];

const EXAMPLE_BILLING = {
  total: 2167,
  subtotal: 2000,
  starting_balance: -1900,
  ending_balance: 267,
  amount_due: 267,
  amount_paid: 0,
  currency: 'CHF',
  created: 1632224786,
  period_end: 1712388150,
  period_start: 1709709750,
  number: 'D86E170-DRAFT',
  invoice_pdf: 'https://pay.stripe.com/invoice/acct_1JcQr3DMNR69GXRk/invst_KIuFX601fHCp1UNwQpGd2vT60DM4cmg/pdf',
  default_payment_method: null,
  default_tax_rates: [
    {
      "active": true,
      "country": "CH",
      "created": 1630585327,
      "description": "VAT",
      "display_name": "VAT",
      "id": "txr_1JVEvjFkvxye2Uaa01CINzJE",
      "inclusive": false,
      "jurisdiction": null,
      "livemode": true,
      "metadata": {},
      "object": "tax_rate",
      "percentage": 8.1,
      "state": null,
      "tax_type": null
    }
  ],
  total_tax_amounts: [
    {
      amount: 167,
    },
  ],
  lines: {
    data: [
      {
        description: 'Cluster usage (in hours)',
        amount: 800,
        currency: 'CHF',
        quantity: 60,
        price: {
          unit_amount_decimal: 0.2,
          nickname: 'agents usage per minute',
        },
      },
      {
        description: 'Plugin usage (in hours)',
        amount: 1200,
        currency: 'CHF',
        quantity: 230,
        price: {
          unit_amount_decimal: 0.1,
          nickname: 'controller usage per minute',
        },
      },
    ],
  },
};

const EXAMPLE_BILLING_ZERO = {
  total: 0,
  subtotal: 0,
  starting_balance: -50000,
  ending_balance: -5000,
  amount_due: 0,
  amount_paid: 0,
  currency: 'CHF',
  created: 1632224786,
  period_end: 1712388150,
  period_start: 1709709750,
  number: 'D86E171-DRAFT',
  invoice_pdf: 'https://pay.stripe.com/invoice/acct_1JcQr3DMNR69GXRk/invst_KIuFX601fHCp1UNwQpGd2vT60DM4cmg/pdf',
  default_payment_method: null,
  default_tax_rates: [
    {
      "active": true,
      "country": "CH",
      "created": 1630585327,
      "description": "VAT",
      "display_name": "VAT",
      "id": "txr_1JVEvjFkvxye2Uaa01CINzJE",
      "inclusive": false,
      "jurisdiction": null,
      "livemode": true,
      "metadata": {},
      "object": "tax_rate",
      "percentage": 7.7,
      "state": null,
      "tax_type": null
    }
  ],
  total_tax_amounts: [
    {
      amount: 0,
    },
  ],
  lines: {
    data: [
      {
        description: 'Cluster usage (in hours)',
        amount: 0,
        currency: 'CHF',
        quantity: 0,
        price: {
          unit_amount_decimal: 0.2,
          nickname: 'agents usage per minute',
        },
      },
      {
        description: 'Plugin usage (in hours)',
        amount: 0,
        currency: 'CHF',
        quantity: 0,
        price: {
          unit_amount_decimal: 0.1,
          nickname: 'controller usage per minute',
        },
      },
    ],
  },
};

const EXAMPLE_BILLING_CREDIT = {
  total: 1292,
  subtotal: 1200,
  starting_balance: -5000,
  ending_balance: -3708,
  amount_due: 0,
  amount_paid: 0,
  currency: 'CHF',
  created: 1632224786,
  period_end: 1712388150,
  period_start: 1709709750,
  number: 'D86E172-DRAFT',
  invoice_pdf: 'https://pay.stripe.com/invoice/acct_1JcQr3DMNR69GXRk/invst_KIuFX601fHCp1UNwQpGd2vT60DM4cmg/pdf',
  default_payment_method: null,
  default_tax_rates: [
    {
      "active": true,
      "country": "CH",
      "created": 1630585327,
      "description": "VAT",
      "display_name": "VAT",
      "id": "txr_1JVEvjFkvxye2Uaa01CINzJE",
      "inclusive": false,
      "jurisdiction": null,
      "livemode": true,
      "metadata": {},
      "object": "tax_rate",
      "percentage": 7.7,
      "state": null,
      "tax_type": null
    }
  ],
  total_tax_amounts: [
    {
      amount: 92,
    },
  ],
  lines: {
    data: [
      {
        description: 'Cluster usage (in hours)',
        amount: 500,
        currency: 'CHF',
        quantity: 2500,
        price: {
          unit_amount_decimal: 0.2,
          nickname: 'agents usage per minute',
        },
      },
      {
        description: 'Plugin usage (in hours)',
        amount: 700,
        currency: 'CHF',
        quantity: 7000,
        price: {
          unit_amount_decimal: 0.1,
          nickname: 'controller usage per minute',
        },
      },
    ],
  },
};

const RESOURCE_PREFIXES = ['assets/svg/icons/'];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isResourceRequest = RESOURCE_PREFIXES.some((resourcePrefix) => request.url.startsWith(resourcePrefix));

    if (isResourceRequest) {
      return next.handle(request);
    }

    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute() {
      if (!environment.simulate) {
        return next.handle(request);
      }
      console.log('handleRoute', url);
      switch (true) {
        case url.endsWith('/session') && method === 'GET':
          return authenticate();
        case url.endsWith('/session/login') && method === 'POST':
          return login();
        case url.endsWith('/session/password/reset') && method === 'POST':
          return passwordReset();
        case url.endsWith('/session/logout') && method === 'POST':
          return logout();
        case url.match(/\/registration\/[\S]+\/password$/) && method === 'POST':
          return updateUserPassword();
        case url.endsWith('/registration/register') && method === 'POST':
          return register();
        case url.match(/\/registration\/verify\/\w+$/) && method === 'GET':
          return verify();
        case url.endsWith('/organization/update-organization') && method === 'POST':
          return updateOrganization();
        case url.match(/\/organization\/users\/[\s\S]+$/) && method === 'GET':
          return getUserById();
        case url.match(/\/organization\/users\/\d+$/) && method === 'PUT':
          return updateUser();
        case url.match(/\/organization\/users\/\d+$/) && method === 'DELETE':
          return deleteUser();
        case url.endsWith('/organization/quotas') && method === 'GET':
          return getQuota();
        case url.endsWith('/organization/quota') && method === 'POST':
          return requestQuota();
        case url.endsWith('/organization/users') && method === 'GET':
          return getUsersForOrganization();
        case url.endsWith('/organization') && method === 'GET':
          return getOrganization();
        case url.endsWith('/organization/add-user') && method === 'POST':
          return addUser();
        case url.match(/\/organization\/delete-user\/[\S]+$/) && method === 'DELETE':
          return deleteUser();
        case url.endsWith('/organization/address') && method === 'GET':
          return getAddress();
        case url.endsWith('/cluster/name/suggestion') && method === 'GET':
          return getNameSuggestion();
        case url.endsWith('/cluster/create') && method === 'POST':
          return createCluster();
        case url.endsWith('/cluster/all') && method === 'GET':
          return getClusterAll();
        case url.endsWith('/cluster/active') && method === 'GET':
          return getClusterAll();
        case url.match(/\/cluster\/[\S]+\/password$/) && method === 'GET':
          return getClusterPassword();
        case url.match(/\/cluster\/[\S]+\/start$/) && method === 'POST':
          return startCluster();
        case url.match(/\/cluster\/[\S]+\/configure\/agentpool$/) && method === 'POST':
          return configureAgentpool();
        case url.match(/\/cluster\/[\S]+\/configure\/autostartstop$/) && method === 'POST':
          return configureAutoStartStop();
        case url.match(/\/cluster\/[\S]+\/wake$/) && method === 'POST':
          return wakeCluster();
        case url.match(/\/cluster\/[\S]+\/status$/) && method === 'GET':
          return getClusterStatus();
        case url.match(/\/cluster\/[\S]+\/stop$/) && method === 'POST':
          return stopCluster();
        case url.match(/\/cluster\/[\S]+\/reset-password$/) && method === 'POST':
          return resetClusterPassword();
        case url.match(/\/cluster\/[\S]+\/delete$/) && method === 'DELETE':
          return killCluster();
        case url.match(/\/cluster\/[\S]+$/) && method === 'GET':
          return getCluster();
        case url.endsWith('/billing/invoices') && method === 'GET':
          return getBillingList();
        case url.endsWith('/billing/invoices/invoice-preview') && method === 'GET':
          return getBilling();
        case url.endsWith('/billing/customer/init-payment-setup') && method === 'GET':
          return getInitPaymentSetup();
        case url.endsWith('/billing/prices') && method === 'GET':
          return getPrices();
        case url.endsWith('/billing/customer/prices') && method === 'GET':
          return getPrices();
        case url.endsWith('/billing/customer/cards-details') && method === 'GET':
          return getCardDetails();
        case url.endsWith('/billing/customer') && method === 'GET':
          return getCustomer();
        default:
          // pass through any requests not handled above
          console.log('handleRoute pass through', url);
          return next.handle(request);
      }
    }

    // simulate a session cookie check
    function authenticate() {
      if (!session || !session.email) {
        return ok({
          authenticated: false,
        });
      }

      const user = users.find((x: { email: any }) => x.email === session.email);
      if (!user) return error('Session for invalid user - logged out');
      const organization = organizations.find((x: { name: string }) => x.name === user.organizationId);
      if (!organization) return error('Session for invalid organization - logged out');

      return ok({
        authenticated: true,
        email: user.email,
        organizationId: user.organizationId,
        fullName: user.fullName,
        registrationState: organization.registrationState,
        userId: user.id,
      });
    }

    function getBilling() {
      let billing = billingList.find((x: { organizationId: any }) => x.organizationId === session.organizationId);
      if (!billing) {
        //create new Billing
        billing = {
          billing: EXAMPLE_BILLING,
          organizationId: session.organizationId,
        };
        billingList.push(billing);
        localStorage.setItem('billingList', JSON.stringify(billingList));
      }

      // deep copy because else we could manipulate the simulated BE by changing variables in the FE
      return ok(deepCopy(billing.billing));
    }

    function getBillingList() {
      /*
      let billing = billingList.filter((x: { organizationId: any;}) => x.organizationId === session.organizationId);
      if (billing.length === 0) {
        //create new Billing
        billing = {
          billing: EXAMPLE_BILLING,
          organizationId: session.organizationId,
        };
        billingList.push(deepCopy(billing));
        billing.billing = EXAMPLE_BILLING_ZERO;
        billingList.push(deepCopy(billing));
        billing.billing = EXAMPLE_BILLING_CREDIT;
        billingList.push(deepCopy(billing));

        localStorage.setItem('billingList', JSON.stringify(billingList));
      }

      let result = {
        data: billing.map((entry: { billing: any; }) => entry.billing)
      }
       */

      let result = {
        data: [EXAMPLE_BILLING, EXAMPLE_BILLING_ZERO, EXAMPLE_BILLING_CREDIT],
      };

      // deep copy because else we could manipulate the simulated BE by changing variables in the FE
      return ok(deepCopy(result));
    }

    function getInitPaymentSetup() {
      return ok({
        clientSecret: 'dsfdsfdsf',
        setupIntentId: 'someSetupIntentId',
      });
    }

    function getPrices() {
      return ok({
        'agent-dotnet-ui-automation': {
          order: 1,
          description: 'Agent [dotnet, browser]',
          amount: 0.03,
          fullDescription:
            'A sophisticated agent that could solve any problem but instead plays java games in the browser. ',
          type: 'AGENT_USAGE',
        },
        'agent-java-ui-automation': {
          order: 0,
          description: 'Agent [java, browser]',
          amount: 0.03,
          fullDescription:
            'A sophisticated agent that could solve any problem but instead plays old flash games in the browser. ',
          type: 'AGENT_USAGE',
        },
        controller: {
          description: 'Controller',
          billingDescription: 'usage (per minute)',
          fullDescription:
            'The controller is the only thing that can control the AI agents. Without it they would destroy the world.',
          amount: 0.045,
          type: 'CONTROLLER_USAGE',
        },
        'controller-plugin-astra': {
          order: 4,
          amount: 4.0,
          description: 'Astra plugin',
          fullDescription:
            'Astra delivered production ready software, on time, with serious and professionalism. Astra knows an incredible range of tech and is a pleasant partner to work with.',
          type: 'CONTROLLER_PLUGIN',
        },
      });
    }

    function getCardDetails() {
      return ok([{"brand":"MASTERCARD","last4":"3882","expMonth":4,"expYear":2028}]);
    }

    function getCustomer() {
      let customer = customerList.find((x: { id: string }) => x.id === session.id);
      if (!customer) {
        //create new Billing
        customer = {
          address: undefined,
          id: session.id,
          balance: -5000,
          created: 1631196766,
          currency: 'chf',
          email: 'tim.rasim@exense.ch',
          invoice_settings: {
            default_payment_method: null, //"pm_1JXo75Fkvxye2Uaac00c3kzy",
          },
          object: 'customer',
        };
        customerList.push(customer);
        localStorage.setItem('customerList', JSON.stringify(customerList));
      }

      console.log('customer', customer);
      return ok(customer);
    }

    function getClusterAll() {
      if (!session.clusters) {
        return ok([]);
      }

      let cluster = clusters.filter((x: { id: any }) => session.clusters.includes(x.id));

      if (cluster) {
        return ok(cluster);
      } else {
        return ok([]);
      }
    }

    function getClusterStatus() {
      let cluster = clusters.find((x: { name: string }) => x.name === lastParamFromUrl(1));
      return ok(cluster.state);
    }

    function getCluster() {
      if (!isLoggedIn()) return unauthorized();

      const cluster = clusters.find((x: { id: string }) => x.id === lastParamFromUrl());

      const tmpClusters = deepCopy(clusters);
      const tmpCluster = tmpClusters.find((x: { id: string }) => x.id === lastParamFromUrl());

      if (cluster.state === ClusterStatus.CREATING) {
        _updateCluster(cluster, {state: ClusterStatus.STARTING})
      } else if (cluster.state === ClusterStatus.STOPPING) {
        console.log('removing cluster password to simulate ended session');
        clusterPasswords[lastParamFromUrl()] = '';
        localStorage.setItem('cluster-passwords', JSON.stringify(clusterPasswords));
      } else if (cluster.state === ClusterStatus.STARTING) {
        _updateCluster(cluster, {state: ClusterStatus.RUNNING})

        if (!tmpCluster.password) {
          console.log('no cluster password - creating');
          clusterPasswords[lastParamFromUrl()] = 'initial-password';
          localStorage.setItem('cluster-passwords', JSON.stringify(clusterPasswords));
        }
      }

      console.log('getCluster', cluster);

      return ok(cluster);
    }

    function getClusterPassword() {
      if (!isLoggedIn()) return unauthorized();

      const id = url.match(/cluster\/([\w]+)\/password/)![1];
      const cluster = clusters.find((x: { id: string }) => x.id === id);
      const password = clusterPasswords[id];

      console.log('getClusterPassword', password);

      if (cluster && cluster.state === ClusterStatus.RUNNING && password) {
        return ok(password);
      } else {
        return ok('');
      }
    }

    function getNameSuggestion() {
      let cluster;

      if (clusters && clusters.length > 0) {
        cluster = clusters.filter((x: { user: any }) => x.user === session.email);
      }

      let result = '';

      if (!cluster || cluster.length === 0) {
        result = session.organizationName + '-1';
      } else {
        const lastChar = cluster[cluster.length - 1].name.slice(-1);
        if (isNaN(parseInt(lastChar))) {
          result = cluster[cluster.length - 1].name.slice(0, -1) + (parseInt(lastChar) + 1);
        } else {
          result = cluster[cluster.length - 1].name + '-1';
        }
      }

      result = result.replace(/[^\w]/gi, '-');
      result = result.toLowerCase();

      console.log('suggesting', result);

      return ok(result);
    }

    function configureAgentpool() {
      const { poolKey, target } = body;

      const tech = 'simulated_tech';
      const type = 'simulated_type';

      let cluster = clusters.find((x: { id: string }) => x.id === lastParamFromUrl(1));

      let agentPools = cluster.agentPools || [];

      let found = undefined;
      for (const pool in agentPools) {
        if (agentPools[pool].key === poolKey) {
          found = pool;
          agentPools[pool].configured = target;
        }
      }

      if (!found) {
        agentPools.push({
          key: poolKey,
          configured: target,
          type,
          tech,
        });
      } else if (target === 0) {
        agentPools.splice(found, 1);
      }

      _updateCluster(cluster, { agentPools: agentPools });

      return ok();
    }

    function configureAutoStartStop() {
      const { autoStartStop, gracePeriodS } = body;

      let cluster = clusters.find((x: { id: string }) => x.id === lastParamFromUrl(2));
      _updateCluster(cluster, {
        autoStartStop: autoStartStop,
        gracePeriodS: gracePeriodS,
      });

      return ok();
    }

    function createCluster() {
      const { name, numberOfAgents, plugins } = body;

      let cluster: Cluster;
      const id = _makeid(10, true);
      cluster = {
        id: id,
        name: name,
        state: ClusterStatus.CREATING,
        version: '23.0',
        url: 'https://' + name + '.fake-step.ch/',
        subscriptionId: 'sub_007',
        autoStartStop: true,
        gracePeriodS: 1800,
        agentPools: [
          {
            key: 'agent-java-ui-automation',
            tech: 'java',
            type: 'ui-automation',
            configured: 1,
            running: numberOfAgents['agent-java-ui-automation'],
          },
        ],
        plugins: [],
      };
      if (plugins?.length > 0) {
        cluster.plugins = [
          {
            key: 'controller-plugin-astra',
            name: 'Astra Plugin',
            description: 'The Logic will flow through steps like a rapid river.',
          },
        ];
      }

      clusters.push(cluster);
      localStorage.setItem('clusters', JSON.stringify(clusters));

      if (session.clusters) {
        session.clusters.push(id);
      } else {
        session.clusters = [id];
      }
      localStorage.setItem('session', JSON.stringify(session));

      return ok(deepCopy(cluster));
    }

    function startCluster() {
      if (!isLoggedIn()) return unauthorized();

      let cluster = clusters.find((x: { id: string }) => x.id === lastParamFromUrl(1));

      _updateCluster(cluster, { state: ClusterStatus.STARTING });

      return ok();
    }

    function wakeCluster() {
      let cluster = clusters.find((x: { name: string }) => x.name === lastParamFromUrl(1));

      _updateCluster(cluster, { state: ClusterStatus.STARTING });

      return ok();
    }

    function stopCluster() {
      if (!isLoggedIn()) return unauthorized();

      let cluster = clusters.find((x: { id: string }) => x.id === lastParamFromUrl(1));

      _updateCluster(cluster, { state: ClusterStatus.STOPPED });

      return ok();
    }

    function resetClusterPassword() {
      if (!isLoggedIn()) return unauthorized();
      clusterPasswords[lastParamFromUrl(1)] = 'N3wPWd_' + _makeid(6);

      localStorage.setItem('cluster-passwords', JSON.stringify(clusterPasswords));

      return ok();
    }

    function killCluster() {
      if (!isLoggedIn()) return unauthorized();

      let cluster = clusters.find((x: { id: string }) => x.id === lastParamFromUrl(1));

      _updateCluster(cluster, {
        state: ClusterStatus.DESTROYED,
      });

      return ok();
    }

    function login() {
      const { email, password } = body;
      const user = users.find((x: { email: any; password: any }) => x.email === email && x.password === password);

      console.log('login', user);
      if (!user) return error('Username or password is incorrect');

      let organization = organizations.find((x: { name: string }) => x.name === user.organizationId);
      if (!organization) {
        organization = {
          name: user.organizationId,
          registrationState: RegistrationState.BILLABLE,
        };
        organizations.push(organization);
        localStorage.setItem('organizations', JSON.stringify(organizations));
      }

      session = {
        email: user.email,
        organizationName: user.organizationId,
        organizationId: user.organizationId,
        id: user.id,
      };
      localStorage.setItem('session', JSON.stringify(session));
      return ok({
        user: user,
        organization: organization,
      });
    }

    function passwordReset() {
      const { email } = body;
      const user = users.find((x: { email: any }) => x.email === email);

      if (!user) return error("Username doesn't exist");

      // send new password as email (not needed for simulation)

      _updateUser(user, { password: 'resettest' });

      return ok();
    }

    function logout() {
      session = {};
      localStorage.setItem('session', '{}');
      return ok();
    }

    function register() {
      const user = body;

      if (users.find((x: { email: any }) => x.email === user.email)) {
        return error('Username "' + user.email + '" is already taken');
      }

      user.userId = _makeid(10);
      user.organizationId = user.organizationName;
      delete user.organizationName;
      users.push(user);
      localStorage.setItem('users', JSON.stringify(users));
      return ok();
    }

    function getUsersForOrganization() {
      if (!isLoggedIn()) return unauthorized();

      const user = users.filter((x: { organizationId: string }) => x.organizationId === session.organizationId);

      return ok(user);
    }

    function getQuota() {
      if (!isLoggedIn()) return unauthorized();

      return ok({ AGENTS_PER_CLUSTER: 10 });
    }

    function requestQuota() {
      if (!isLoggedIn()) return unauthorized();

      const quotaRequest: { quotaKey: string; value: number; reason: string } = body;

      const organization = organizations.find((x: { name: string }) => x.name === session.organizationName);

      if (!organization.customFields) {
        organization.customFields = {};
      }

      if (!organization.pendingQuotaRequests) {
        organization.pendingQuotaRequests = [quotaRequest.quotaKey];
      } else {
        organization.pendingQuotaRequests.push(quotaRequest.quotaKey);
        organization.pendingQuotaRequests = [...new Set(organization.pendingQuotaRequests)];
      }

      localStorage.setItem('organizations', JSON.stringify(organizations));

      return ok();
    }

    function verify() {
      if (lastParamFromUrl() === 'testtest') {
        return ok();
      } else {
        return error('invalid token');
      }
    }

    function getOrganization() {
      if (!isLoggedIn()) return unauthorized();

      const organization = organizations.find((x: { name: string }) => x.name === session.organizationName);
      return ok(deepCopy(organization));
    }

    function updateOrganization() {
      if (!isLoggedIn()) return unauthorized();

      let params = body;

      let customer = customerList.find((x: { id: string }) => x.id === session.id);

      customer.invoice_settings.default_payment_method = 'pm_1JXo75Fkvxye2Uaac00c3kzy';

      let organization = organizations.find((x: { name: string }) => x.name === session.organizationId);
      organization.addressId = _makeid(8);
      localStorage.setItem('organizations', JSON.stringify(organizations));

      customer.address = {
        id: organization.addressId,
        street: params.street,
        postalCode: params.postalCode,
        city: params.city,
        country: params.country,
        state: params.state,
        phoneNumber: params.phoneNumber,
      };

      localStorage.setItem('customerList', JSON.stringify(customerList));

      return ok();
    }

    function getUserById() {
      if (!isLoggedIn()) return unauthorized();

      const user = users.find((x: { id: string }) => x.id === lastParamFromUrl());
      return ok(deepCopy(user));
    }

    function updateUser() {
      if (!isLoggedIn()) return unauthorized();

      let params = body;
      let user = users.find((x: { id: number }) => x.id === idFromUrl());

      // only update password if entered
      // FIXME: user object already contains new (empty) password but this is just a mock so it might be ok
      if (!params.password) {
        delete params.password;
      }

      // update and save user
      _updateUser(user, params);

      return ok();
    }

    function updateUserPassword() {
      if (!isLoggedIn()) return unauthorized();

      let params = body;

      const id = url.match(/registration\/([\w]+)\/password/)![1];
      let user = users.find((x: { id: string }) => x.id === id);

      if (user.password !== params.oldPassword) {
        return error('Please provide your current password');
      }

      // update and save user
      _updateUser(user, { password: params.newPassword });

      return ok();
    }

    function _updateUser(user: User, params: any) {
      Object.assign(user, params);
      localStorage.setItem('users', JSON.stringify(users));
    }

    function _updateCluster(cluster: Cluster, params: any) {
      Object.assign(cluster, params);
      localStorage.setItem('clusters', JSON.stringify(clusters));
    }

    function addUser() {
      const user = body;

      if (users.find((x: { email: any }) => x.email === user.email)) {
        return error('Username "' + user.email + '" is already taken');
      }

      user.id = users.length ? Math.max(...users.map((x: { id: any }) => x.id)) + 1 : 1;

      const sessionUser = users.find((x: { email: string }) => x.email === session.email);
      user.organizationId = sessionUser.organizationId;

      users.push(user);
      localStorage.setItem('users', JSON.stringify(users));
      return ok();
    }

    function deleteUser() {
      if (!isLoggedIn()) return unauthorized();

      users = users.filter((x: { email: string }) => x.email !== lastParamFromUrl());
      localStorage.setItem('users', JSON.stringify(users));
      return ok();
    }

    function getAddress() {
      let address = undefined;
      let customer = customerList.find((x: { id: string }) => x.id === session.id);

      if (customer) {
        address = customer.address;
      }

      if (address) {
        return ok(address);
      } else {
        return ok({
            "customFields": null,
            "name": "TEST-NAME",
            "street": "TEST_STREET 3",
            "city": "TEST_CITY",
            "state": "TEST_STATE",
            "postalCode": "1234",
            "country": "Switzerland",
            "id": "6571e286f55c6e332f29be2b"
        });
      }

    }

    // helper functions

    function ok(body?: any) {
      if (body && body.password) {
        delete body.password;
      }

      return of(new HttpResponse({ status: 200, body }));
    }

    function error(errorMessage: string, errorName?: string) {
      return throwError({ errorName: errorName, errorMessage: errorMessage });
    }

    function unauthorized() {
      return throwError({ status: 401, errorMessage: 'Unauthorised' });
    }

    function isLoggedIn() {
      return !!session?.email;
    }

    function idFromUrl() {
      const urlParts = url.split('/');
      return parseInt(urlParts[urlParts.length - 1]);
    }

    function lastParamFromUrl(moveIndex: number = 0) {
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1 - moveIndex];
    }

    function _makeid(length: number, numberOnly: boolean = false) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      if (numberOnly) {
        characters = '0123456789';
      }
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    }

    function deepCopy(obj: any): any {
      let copy: { [k: string]: any };

      // Handle the 3 simple types, and null or undefined
      if (null == obj || 'object' != typeof obj) return obj;

      // Handle Date
      if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
          copy[i] = deepCopy(obj[i]);
        }
        return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
        copy = {};
        for (let attr in obj) {
          if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
      }

      throw new Error("Unable to copy obj! Its type isn't supported.");
    }
  }
}

export const simulatedBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
