import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AddPaymentDialogComponent } from '../_dialog/add-payment-dialog/add-payment-dialog.component';
import { AddPaymentWizardDialogComponent } from '../_dialog/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { RegistrationState } from '../_models/registration-state.enum';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard  {
  constructor(private matDialog: MatDialog, private accountService: AccountService) {}

  canActivate(): Observable<boolean> {
    if (this.accountService.organizationValue?.registrationState === RegistrationState.BILLABLE) {
      return of(true);
    }

    const addPaymentDialogRef = this.matDialog.open(AddPaymentDialogComponent, {
      disableClose: true,
    });

    return addPaymentDialogRef.afterClosed().pipe(
      switchMap((addPaymentMethod) => {
        if (!addPaymentMethod) {
          return of(false);
        }

        const dialogRef = this.matDialog.open(AddPaymentWizardDialogComponent, {
          // See `mat-dialog.scss`
          panelClass: 'dialog-full-screen',
        });

        return dialogRef.afterClosed().pipe(
          map((wizardCompleted) => {
            if (!wizardCompleted) {
              return false;
            }

            return true;
          })
        );
      })
    );
  }
}
