import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, ReplaySubject, Subscription, throwError } from 'rxjs';
import { Organization } from '../_models/organization';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

interface Dictionary {
  [index: string]: Subscription;
}

@Injectable({
  providedIn: 'root',
})
export class BaseServiceService {
  protected cache: {
    [key: string]: {
      subject?: ReplaySubject<any>;
      observable?: Observable<any>;
      poll?: Subscription;
      value?: any;
    };
  } = {};
  private intervalSubscriptions = {} as Dictionary;

  constructor(protected http: HttpClient) {}

  createCachedObservable<Type>(name: string) {
    this.cache[name] = {};
    this.cache[name].subject = new ReplaySubject<Type>(1);
    this.cache[name].value = undefined;
    this.cache[name].observable = this.cache[name].subject!.asObservable();
    this.cache[name].poll = undefined;
  }

  getCachedObservable<Type>(name: string): Observable<Type> {
    if (!this.cache[name]?.observable) {
      throwError('Cache with name: "' + name + '" does not exist on getCachedObservable');
    }

    // Types are hard to check in JS so we don't for now
    return this.cache[name].observable!;
  }

  debugCache() {
    console.log('debugCache', this.cache);
  }

  getCachedValue<Type>(name: string): Type {
    if (!this.cache[name]) {
      throwError('Cache with name: "' + name + '" does not exist on getCachedValue');
    }

    // Types are hard to check in JS so we don't for now
    return this.cache[name].value;
  }

  updateCachedValue<Type>(name: string, value: Type) {
    if (!this.cache[name]) {
      throwError('cached item: "' + name + '" does not exist. Did you call createCachedObservable?');
    }

    this.cache[name].value = value;
    this.cache[name].subject!.next(value);
  }

  /*
   *  Polls observable, while a poll is in progress all requests will instead get a reference to the future observable
   */
  pollObservable<Type>(requestUrl: string, name: string) {
    if (!this.cache[name].poll) {
      this.cache[name].poll = this.http.get<Type>(`${environment.apiUrl}/${requestUrl}`).subscribe((value: Type) => {
        this.updateCachedValue<Type>(name, value);
        this.cache[name].value = value;
        this.cache[name].poll = undefined;
      });
    }

    return this.getCachedObservable<Type>(name);
  }

  invalidateCache() {
    for (let key in this.cache) {
      delete this.cache[key];
    }
  }

  createIntervalSubscription(name: string, pollingFunction: any, _interval = 15000) {
    // FIXME: we need a better (and saver) way to handle interval subscriptions as to prevent overflows
    if (this.intervalSubscriptions[name]) {
      this.intervalSubscriptions[name].unsubscribe();
    }

    this.intervalSubscriptions[name] = interval(_interval).subscribe(pollingFunction);
  }

  cancelAllIntervals() {
    for (let key in this.intervalSubscriptions) {
      this.intervalSubscriptions[key].unsubscribe();
    }
  }
}
