import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/_services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss'],
})
export class PasswordResetDialogComponent {
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  loading: boolean = false;
  resetPasswordSubmitted: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    public matDialogRef: MatDialogRef<PasswordResetDialogComponent>
  ) {}

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    const { email } = this.formGroup.value;

    this.loading = true;

    this.accountService.passwordReset(email).subscribe({
      next: () => {
        this.resetPasswordSubmitted = true;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
