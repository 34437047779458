import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';

export interface ConfirmationDialogData {
  headingTemplate?: TemplateRef<{}>;
  headingContext?: Object;
  descriptionTemplate?: TemplateRef<{}>;
  descriptionContext?: Object;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  constructor(
    private matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationDialogData
  ) {}

  onCancel(): void {
    this.matDialogRef.close(false);
  }

  onContinue(): void {
    this.matDialogRef.close(true);
  }
}
