<div class="p-3 bg-white rounded-full">
  <app-svg-icon name="user-01" class="text-gray-600"></app-svg-icon>
</div>

<div class="flex-1 min-w-0 flex">
  <div class="flex-1 min-w-0">
    <div class="text-sm leading-sm font-medium text-white whitespace-nowrap overflow-hidden overflow-ellipsis">
      {{ fullName }}
    </div>

    <div class="text-sm leading-sm text-primary-200 whitespace-nowrap overflow-hidden overflow-ellipsis">
      {{ email }}
    </div>
  </div>

  <button
    type="button"
    (click)="logout.emit()"
    class="self-start p-2 rounded-full hover:bg-primary-600 focus:bg-primary-600 focus-ring-primary-2 relative top-2 -right-2"
  >
    <app-svg-icon name="log-out-01" class="text-primary-300"></app-svg-icon>
  </button>
</div>
