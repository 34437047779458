<div class="center">
  <ng-container *ngIf="!error; else showError">
    <p *ngIf="!starting">Waiting for mouse movement before starting {{clusterName}}...
      <app-info-bubbel message="Mouse movement is required to prevent unintended cluster wakeup - if you can read this text the mouse recognition failed."></app-info-bubbel>
    </p>
    <p *ngIf="starting">Starting {{clusterName}} -  in progress</p>
  </ng-container>

  <ng-template #showError>
    <p *ngIf="error.errorMessage" class="error">{{ error.errorMessage }}</p>
    <a *ngIf="error.errorName" href="/login">Login to Step Portal to fix issue</a>
  </ng-template>
</div>
