<h1 class="text-3xl leading-3xl font-medium text-gray-900 pb-6">Settings</h1>

<app-tabset [activeTabIndex]="activeTabIndex" (activeTabIndexChange)="onActiveTabIndexChange($event)">
  <app-tab title="User settings">
    <div class="pt-6">
      <div class="flex flex-col gap-8">
        <app-user-personal-info-settings></app-user-personal-info-settings>
        <app-user-change-password-settings></app-user-change-password-settings>
      </div>
    </div>
  </app-tab>

  <app-tab title="Manage organization">
    <div class="pt-6">
      <div class="flex flex-col gap-8">
        <app-organization-address-settings></app-organization-address-settings>
        <app-organization-members-settings></app-organization-members-settings>
        <app-organization-account-settings></app-organization-account-settings>
      </div>
    </div>
  </app-tab>

  <app-tab title="Payment method">
    <div class="pt-6">
      <app-stripe-dismissible-notice></app-stripe-dismissible-notice>
      <app-payments-dismissible-notice></app-payments-dismissible-notice>

      <div class="flex flex-col gap-8">
        <app-payment-card-settings></app-payment-card-settings>
        <!-- <app-payment-address-settings></app-payment-address-settings> -->
      </div>
    </div>
  </app-tab>
</app-tabset>
