import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.less']
})
export class MaintenanceComponent implements OnInit {

  constructor(private router: Router,
    private http: HttpClient,) { }

  ngOnInit(): void {
    this.checkServerAvailability();
    setInterval(() => { this.checkServerAvailability() }, 1000 * 60 * 10 /* 10 minutes */);
  }

  checkServerAvailability(){
    this.http.get<any>(`${environment.apiUrl}/session`).subscribe(() => this.router.navigate(['/login']));
  }

}
