import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, delay, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Cluster, ClusterListItem } from '../_models/cluster';
import { BaseServiceService } from './base-service.service';

interface ObservableCluster {
  id: string;
  subject: ReplaySubject<Cluster>;
  observable?: Observable<Cluster>;
}

@Injectable({
  providedIn: 'root',
})
export class PortalService extends BaseServiceService {
  private clusterListSubject: ReplaySubject<ClusterListItem[]>;
  private readonly clusterListObservable: Observable<ClusterListItem[]>;

  // To avoid confusion: clusterList is a list of clusters requested from the server
  // clusters is a list of individually requested clusters
  private clusters: ObservableCluster[] = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(http: HttpClient) {
    super(http);
    this.clusterListSubject = new ReplaySubject<ClusterListItem[]>(1);
    this.clusterListObservable = this.clusterListSubject.asObservable();
  }

  createCluster(name: string, numberOfAgents: {}, plugins: string[]) {
    return this.http.post<Cluster>(`${environment.apiUrl}/cluster/create`, {
      name,
      numberOfAgents,
      plugins,
    });
  }

  retryCreateCluster(id: string) {
    return this.http.post<Cluster>(`${environment.apiUrl}/cluster/retry-create/` + id, {});
  }

  getNameSuggestion(): Observable<string> {
    // @ts-ignore
    return this.http.get<string>(`${environment.apiUrl}/cluster/name/suggestion`, { responseType: 'text' });
  }

  getCluster(id: string) {
    let cluster: ObservableCluster;
    if (this.clusters.find((e) => e.id === id)) {
      cluster = this.clusters.find((e) => e.id === id)!;
    } else {
      cluster = {
        id: id,
        subject: new ReplaySubject<Cluster>(1),
      };
      cluster.observable = cluster.subject.asObservable();

      this.clusters.push(cluster);

      this.createIntervalSubscription('cluster_' + id, () => this.refreshCluster(id));
    }

    this.refreshCluster(id);

    return cluster.observable!;
  }

  refreshCluster(id: string) {
    const cluster = this.clusters.find((e) => e.id === id);
    if (!cluster) {
      throwError("Undefined cluster can't be refreshed");
      return;
    }

    this.http.get<Cluster>(`${environment.apiUrl}/cluster/${id}`).subscribe((resultCluster) => {
      cluster.subject.next(resultCluster);
    });
  }

  getClusterList() {
    this.createIntervalSubscription('clusterList', () => this.refreshClusterList());

    this.refreshClusterList();

    return this.clusterListObservable;
  }

  refreshClusterList() {
    this.http.get<ClusterListItem[]>(`${environment.apiUrl}/cluster/active`).subscribe((clusterList) => {
      this.clusterListSubject.next(clusterList);
    });
  }

  setNumberOfAgents(id: string, poolKey: string, target: number) {
    return this.http.post<any>(`${environment.apiUrl}/cluster/${id}/configure/agentpool`, {
      poolKey: poolKey,
      target: target,
    });
  }

  setAutoStartStop(id: string, autoStartStop: boolean, gracePeriod: number) {
    return this.http.post<any>(`${environment.apiUrl}/cluster/${id}/configure/autostartstop`, {
      autoStartStop: autoStartStop,
      gracePeriodS: gracePeriod,
    });
  }

  requestQuota(quotaKey: string, value: number, reason: string) {
    return this.http.post<any>(`${environment.apiUrl}/organization/quota`, {
      quotaKey,
      value,
      reason,
    });
  }

  startCluster(id: string) {
    return this.http.post<any>(`${environment.apiUrl}/cluster/${id}/start`, {});
  }

  stopCluster(id: string) {
    return this.http.post(`${environment.apiUrl}/cluster/${id}/stop`, {});
  }

  resetClusterPassword(id: string) {
    return this.http.post(`${environment.apiUrl}/cluster/${id}/reset-password`, {});
  }

  deleteCluster(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}/cluster/${id}/delete`);
  }

  /* will retrieve the initial cluster password but only works until end of cluster creation session */
  retrieveInitialClusterPassword(id: string): Observable<string> {
    // @ts-ignore
    return this.http.get<string>(`${environment.apiUrl}/cluster/${id}/password`, { responseType: 'text' });
  }
}
