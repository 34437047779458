<h5 class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">Plugins (optional)</h5>

<p class="text-sm leading-sm text-gray-500 pb-5">Enhance Steps capabilities through the usage of plugins.</p>

<div *ngIf="pluginTypes.length" class="flex flex-col gap-5 pb-6">
  <ng-container *ngFor="let pluginType of pluginTypes">
    <app-plugin-configuration
      *ngIf="pluginByType[pluginType] as plugin"
      [plugin]="plugin"
      [billingPeriod]="billingPeriod"
      [customerPrices]="customerPrices"
      (pluginRemove)="onRemovePlugin($event)"
    ></app-plugin-configuration>
  </ng-container>
</div>

<button
  type="button"
  [matMenuTriggerFor]="pluginsMenu"
  [disabled]="allPluginsAdded"
  class="text-sm leading-sm font-medium text-gray-500 flex items-center gap-2 focus:outline-none focus:text-primary-400"
>
  <app-svg-icon name="plus"></app-svg-icon>
  Add another plugin
</button>

<mat-menu #pluginsMenu>
  <ng-container *ngFor="let plugin of plugins">
    <button
      *ngIf="!activeByPluginType[plugin.type]"
      type="button"
      matRipple
      (click)="onAddPlugin(plugin.type)"
      mat-menu-item
    >
      <div class="flex items-start gap-3 text-left">
        <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
          <img [src]="plugin.imgSrc" [alt]="plugin.imgAlt" class="w-full h-full" />
        </div>

        <div class="flex-1 text-sm leading-sm">
          <div class="font-medium text-gray-700">{{ plugin.title }}</div>
          <div class="text-gray-500">{{ plugin.description }}</div>
        </div>
      </div>
    </button>
  </ng-container>
</mat-menu>
