import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar-profile',
  templateUrl: './sidebar-profile.component.html',
  styleUrls: ['./sidebar-profile.component.scss'],
})
export class SidebarProfileComponent {
  @Input() fullName!: string;
  @Input() email!: string;

  @Output() logout = new EventEmitter<void>();

}
