<h3 class="text-lg leading-lg font-medium text-gray-900 pb-1">Card details</h3>

<p class="text-sm leading-sm text-gray-500">Modify your credit card information</p>

<div class="pt-4 pb-6">
  <hr />
</div>

<app-skeleton-loader
  *ngIf="!editMode; else editModeTemplate"
  [loading]="loading"
  [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
  [withBorder]="true"
  borderWidth="1px"
>
  <app-card-details [cardDetails]="cardDetails" (editClick)="setEditMode(true)"></app-card-details>
</app-skeleton-loader>

<ng-template #editModeTemplate>
  <app-card-settings
    submitButtonTitle="Update card details"
    [withCancelButton]="!!cardDetails"
    (cancelClick)="setEditMode(false)"
    (formSubmit)="onSubmit($event)"
  ></app-card-settings>
</ng-template>
