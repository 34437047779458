import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss'],
})
export class CostsComponent {
  @Input() amount!: string;
  @Input() unit!: string;
}
