<ng-container *ngIf="!resetPasswordSubmitted; else resetPasswordSubmittedTemplate">
  <app-dialog-header icon="send-03" (dialogClose)="matDialogRef.close()"></app-dialog-header>

  <app-dialog-body>
    <ng-template #heading>Password reset</ng-template>
    <ng-template #description>We will email a password recovery link to the mail address if valid.</ng-template>

    <form [formGroup]="formGroup" (submit)="onSubmit()">
      <app-label [required]="true" [control]="formGroup.controls.email" title="Email" #emailLabel></app-label>
      <mat-form-field subscriptSizing="dynamic" class="pb-6 w-full">
        <app-svg-icon name="mail-01" matPrefix></app-svg-icon>

        <input (focusin)="emailLabel.focus()" (focusout)="emailLabel.focusout()" matInput name="email" placeholder="Email" type="email" autocomplete="email" formControlName="email" class="relative z-[1]" />

        <mat-error *ngIf="formGroup.controls.email.hasError('required')">Email is required</mat-error>

        <mat-error *ngIf="formGroup.controls.email.hasError('email')"> Please enter a valid email address </mat-error>
      </mat-form-field>

      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        [fluid]="true"
        [loading]="loading"
      >
        Request
      </app-button>
    </form>
  </app-dialog-body>
</ng-container>

<ng-template #resetPasswordSubmittedTemplate>
  <app-dialog-header icon="mail-01" (dialogClose)="matDialogRef.close()"></app-dialog-header>

  <app-dialog-body>
    <ng-template #description>Password reset email was send, please also check your spam folder.</ng-template>

    <app-button
      [buttonType]="ButtonType.BUTTON"
      [buttonStyle]="ButtonStyle.BTN_1"
      [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      [fluid]="true"
      (buttonClick)="matDialogRef.close()"
    >
      Close
    </app-button>
  </app-dialog-body>
</ng-template>
