<form #form [formGroup]="formGroup" (submit)="onSubmit()">
  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Street address *</label>

    <app-skeleton-loader
      [loading]="loading"
      [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
      [withBorder]="true"
      borderWidth="1px"
      class="flex-[4]"
    >
      <mat-form-field subscriptSizing="dynamic" class="w-full" [class.with-error]="formGroup.controls.street.hasError('required')">
        <input placeholder="Street address" type="text" name="street" autocomplete="address-line1" matInput formControlName="street" class="relative z-[1]" />

        <mat-error *ngIf="formGroup.controls.street.hasError('required')">Street address is required</mat-error>
      </mat-form-field>
    </app-skeleton-loader>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">City * / ZIP *</label>

    <div class="flex-[4] flex gap-6">
      <app-skeleton-loader
        [loading]="loading"
        [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
        [withBorder]="true"
        borderWidth="1px"
        class="flex-[4]"
      >
        <mat-form-field subscriptSizing="dynamic" class="w-full" [class.with-error]="formGroup.controls.city.hasError('required')">
          <input placeholder="City" type="text" name="city" autocomplete="address-level2" matInput formControlName="city" class="relative z-[1]" />

          <mat-error *ngIf="formGroup.controls.city.hasError('required')">City is required</mat-error>
        </mat-form-field>
      </app-skeleton-loader>

      <app-skeleton-loader
        [loading]="loading"
        [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
        [withBorder]="true"
        borderWidth="1px"
        class="flex-[4]"
      >
        <mat-form-field subscriptSizing="dynamic" class="w-full" [class.with-error]="formGroup.controls.postalCode.hasError('required')">
          <input
            type="text"
            name="postalCode"
            autocomplete="postal-code"
            matInput
            placeholder="ZIP"
            formControlName="postalCode"
            class="relative z-[1]"
          />

          <mat-error *ngIf="formGroup.controls.postalCode.hasError('required')">ZIP is required</mat-error>
        </mat-form-field>
      </app-skeleton-loader>
    </div>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">State</label>

    <app-skeleton-loader
      [loading]="loading"
      [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
      [withBorder]="true"
      borderWidth="1px"
      class="flex-[4]"
    >
      <mat-form-field subscriptSizing="dynamic" class="w-full" [class.with-error]="formGroup.controls.state.hasError('required')">
        <input placeholder="State" type="text" name="state" autocomplete="address-level1" matInput formControlName="state" class="relative z-[1]" />

      </mat-form-field>
    </app-skeleton-loader>

    <div class="flex-[1]"></div>
  </div>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex">
    <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Country *</label>

    <app-skeleton-loader
      [loading]="loading"
      [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
      [withBorder]="true"
      borderWidth="1px"
      class="flex-[4]"
    >
      <app-country-select
        [countries]="countries"
        [countryControl]="formGroup.controls.country | asFormControl"
        [formElement]="form"
      ></app-country-select>
    </app-skeleton-loader>

    <div class="flex-[1]"></div>
  </div>

  <ng-container *ngIf="withPhoneNumber">
    <div class="py-5">
      <hr />
    </div>

    <div class="flex">
      <label class="flex-[2] text-sm leading-sm text-gray-700 font-medium">Phone number</label>

      <app-skeleton-loader
        [loading]="loading"
        [rounding]="SkeletonLoaderRounding.ROUNDED_MD"
        [withBorder]="true"
        borderWidth="1px"
        class="flex-[4]"
      >
        <mat-form-field subscriptSizing="dynamic" class="w-full" [class.with-error]="formGroup.controls.phoneNumber.hasError('required')">
          <input
            type="tel"
            name="phoneNumber"
            autocomplete="tel"
            matInput
            placeholder="Phone number"
            formControlName="phoneNumber"
            class="relative z-[1]"
          />
        </mat-form-field>
      </app-skeleton-loader>

      <div class="flex-[1]"></div>
    </div>
  </ng-container>

  <div class="py-5">
    <hr />
  </div>

  <div class="pr-1 pb-1 flex justify-end">
    <div class="flex gap-3">
      <app-button
        *ngIf="formGroup.dirty"
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onReset()"
      >
        Cancel
      </app-button>

      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      >
        {{ submitButtonTitle }}
      </app-button>
    </div>
  </div>
</form>
