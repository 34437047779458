import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from "@angular/router";
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Address } from '../_models/address';
import { LoginResponse } from '../_models/login-response.interface';
import { Organization } from '../_models/organization';
import { RegistrationState } from '../_models/registration-state.enum';
import { User } from '../_models/user';
import { BaseServiceService } from './base-service.service';
import { BillingService } from './billing.service';
import { PortalService } from './portal.service';
import { SpinnerService } from './spinner.service';

interface Snack {
  message: string;
  triggerRoute: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseServiceService {
  private organizations: Organization[] = [];

  private snacks: Snack[] = [];

  constructor(
    private router: Router,
    http: HttpClient,
    private portalService: PortalService,
    private billingService: BillingService,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService
  ) {
    super(http);
    this.createCachedObservable<User>('user');
    this.createCachedObservable<Organization>('organization');

    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe((val: NavigationEnd) => this.checkForSnacks(val));
  }

  getOrganization(): Observable<Organization> {
    return this.http.get<Organization>(`${environment.apiUrl}/organization`).pipe(
      map(({ name, registrationState, id, addressId, phoneNumber, customFields, pendingQuotaRequests }) => {
        return new Organization(
          name,
          registrationState,
          id,
          addressId,
          phoneNumber,
          customFields,
          pendingQuotaRequests
        );
      }),
      tap((organization) => {
        this.updateCachedValue<Organization>('organization', {
          ...(this.organizationValue ?? {}),
          ...organization,
        });
      })
    );
  }

  register(registrationData: any) {
    return this.http.post(`${environment.apiUrl}/registration/register`, registrationData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  updateOrganization(addressData: any) {
    const payload = {
      ...addressData,
      name: this.organizationValue?.name,
      country: addressData.country.name,
    };

    return this.http.post(`${environment.apiUrl}/organization/update-organization`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  joinOrganization(registrationData: any) {
    return this.http.post(`${environment.apiUrl}/organization/join`, registrationData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  addUser(registrationData: any) {
    return this.http.post(`${environment.apiUrl}/organization/add-user`, registrationData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  changeUserPassword(userId: string, oldPassword: string, newPassword: string) {
    return this.http.post(
      `${environment.apiUrl}/registration/${userId}/password`,
      { oldPassword: oldPassword, newPassword: newPassword },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  getAddress() {
    return this.http.get<Address>(`${environment.apiUrl}/organization/address`);
  }

  authenticateAsObservable(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/session`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 502 || error.status === 503) {
          this.router.navigate(['maintenance']);
        }
        return throwError(error);
      }),
      switchMap((userData: any) => {
        this.updateCachedValue<User>('user', {
          email: userData.email,
          organizationId: userData.organizationId,
          registrationState: userData.registrationState,
          fullName: userData.fullName,
          id: userData.userId,
          organizationOwner: userData.organizationOwner,
        });
        if (userData.authenticated) {
          return this.pollObservable<Organization>('organization', 'organization').pipe(
            map((organizationData) => {
              if (organizationData) {
                this._updateOrganization(organizationData);
                return {
                  user: this.getCachedValue<User>('user'),
                  organization: organizationData,
                };
              } else {
                return undefined;
              }
            })
          );
        } else {
          return of(undefined);
        }
      })
    );
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.http.post(`${environment.apiUrl}/session/login`, { email, password }).pipe(
      map((data: any) => {
        if (data.user) {
          this.authenticateAsObservable();
        }
        return data;
      })
    );
  }

  passwordReset(email: string) {
    return this.http.post(`${environment.apiUrl}/registration/password-reset-request`, { email });
  }

  passwordResetVerify(id: string, newPassword: string) {
    return this.http.post(`${environment.apiUrl}/registration/password-reset-request/${id}/verify`, { newPassword });
  }

  logout() {
    this.spinnerService.show();

    this.http
      .post(`${environment.apiUrl}/session/logout`, {})
      .pipe(tap(() => this.spinnerService.hide()))
      .subscribe(() => this.handleLogout());
  }

  handleLogout() {
    // cancel interval polling
    this.portalService.cancelAllIntervals();
    this.billingService.cancelAllIntervals();

    // invalidate cache FIXME: we should not have to target individual services. Maybe some kind of subscription?
    this.billingService.invalidateCache();
    this.invalidateCache();

    // reloading the page to solve issue with deleted observables
    window.location.href = '/';
  }

  getUsers() {
    return this.http.get<User[]>(`${environment.apiUrl}/organization/users`);
  }

  public get userValue(): User | undefined {
    return this.getCachedValue<User>('user');
  }

  public get user(): Observable<User> {
    return this.getCachedObservable<User>('user');
  }

  public get organizationValue(): Organization | undefined {
    return this.getCachedValue<Organization>('organization');
  }

  public get organization(): Observable<Organization> {
    if (!this.getCachedValue<Organization>('organization')) {
      this.pollObservable<Organization>('organization', 'organization');
    }
    return this.getCachedObservable<Organization>('organization');
  }

  getUserById(id: string) {
    return this.http.get<User>(`${environment.apiUrl}/organization/users/${id}`);
  }

  updateUser(email: string, value: any) {
    return this.http.put(`${environment.apiUrl}/users/${email}`, value).pipe(
      map((x) => {
        // update stored company if the logged in company updated their own record
        if (email == this.userValue?.email) {
          // update local storage
          const user = { ...this.userValue, ...value };
          this.updateCachedValue<User>('user', user);
        }
        return x;
      })
    );
  }

  private _updateOrganization(organization: Organization) {
    if (this.organizations?.some((search) => search.id === organization.id)) {
      const organization = this.organizations.find((organization) => organization.id === organization.id)!;
      const index = this.organizations.indexOf(organization);
      this.organizations[index] = organization;
    } else {
      //add to array if it's not in there already
      this.organizations?.push(organization);
    }
  }

  verifyMail(id: string) {
    return this.http.get(`${environment.apiUrl}/registration/verify/${id}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.getCachedObservable<User>('user').pipe(
      first((user) => !!user.email),
      map((user) => !!user.email)
    );
  }

  isAuthenticated(): Observable<boolean> {
    if (!!this.userValue?.email && this.userValue?.registrationState !== RegistrationState.NEW) {
      return of(true);
    } else {
      const authenticateAsObservable = this.authenticateAsObservable().pipe(
        map((data) => {
          return !!data && data?.organization?.registrationState !== RegistrationState.NEW;
        })
      );
      authenticateAsObservable.subscribe();
      return authenticateAsObservable;
    }
  }

  deleteUser(id: string) {
    return this.http.delete(`${environment.apiUrl}/organization/delete-user/${id}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  /*
   *  (optional) triggerRoute: the route on which this snack will be shown, any route by default
   */
  addSnack(message: string, triggerRoute?: string, duration?: number) {
    this.snacks.push({
      message: message,
      triggerRoute: triggerRoute ?? 'ALL',
      duration: duration,
    });
  }

  checkForSnacks(event: NavigationEnd) {
    for (let key in this.snacks) {
      if (this.snacks[key].triggerRoute === 'ALL' || event.url.endsWith(this.snacks[key].triggerRoute)) {
        const openSnack = this.snackBar.open(this.snacks[key].message);
        setTimeout(() => {
          openSnack.dismiss();
        }, this.snacks[key].duration ?? 5000);
        delete this.snacks[key];
      }
    }
  }
}
