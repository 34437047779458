<app-dialog-header icon="lock-01" (dialogClose)="onCancel()"></app-dialog-header>

<app-dialog-body>
  <ng-template #heading>Edit user credentials</ng-template>
  <ng-template #description>
    You are about to modify the credentials for
    <strong>{{ dialogData.user.email }}</strong>
  </ng-template>

  <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
    <app-label [required]="true" [control]="formGroup.controls.organizationName" title="Organization name" #organizationNameLabel></app-label>
    <mat-form-field subscriptSizing="dynamic">
      <input (focusin)="organizationNameLabel.focus()" (focusout)="organizationNameLabel.focusout()" type="text" name="organizationName" matInput formControlName="organizationName" />
    </mat-form-field>

    <app-label [required]="true" [control]="formGroup.controls.email" title="Email" #emailLabel></app-label>
    <mat-form-field subscriptSizing="dynamic">
      <app-svg-icon name="mail-01" matPrefix></app-svg-icon>

      <input (focusin)="emailLabel.focus()" (focusout)="emailLabel.focusout()" type="text" name="email" autocomplete="email" matInput formControlName="email" />
    </mat-form-field>

    <app-label [required]="true" [control]="formGroup.controls.currentPassword" title="Current password" #currentPasswordLabel></app-label>
    <mat-form-field subscriptSizing="dynamic" class="with-suffix">
      <input
        (focusin)="currentPasswordLabel.focus()"
        (focusout)="currentPasswordLabel.focusout()"
        #currentPasswordInput
        matInput
        placeholder="Current password"
        name="currentPassword"
        type="password"
        autocomplete="current-password"
        formControlName="currentPassword"
        class="relative z-[1]"
      />

      <app-password-visibility [inputElement]="currentPasswordInput" matSuffix></app-password-visibility>

      <mat-error *ngIf="formGroup.controls.currentPassword.hasError('required')">
        Current password is required
      </mat-error>
    </mat-form-field>

    <app-label [required]="true" [control]="formGroup.controls.newPassword" title="New password" #newPasswordLabel></app-label>
    <mat-form-field subscriptSizing="dynamic" class="with-suffix">
      <input
        (focusin)="newPasswordLabel.focus()"
        (focusout)="newPasswordLabel.focusout()"
        #newPasswordInput
        matInput
        placeholder="New password"
        name="newPassword"
        type="password"
        autocomplete="new-password"
        formControlName="newPassword"
        class="relative z-[1]"
      />

      <app-password-visibility [inputElement]="newPasswordInput" matSuffix></app-password-visibility>

      <mat-hint>
        A secure password requires 8 letters that include: special characters, numbers, small and capital letters
      </mat-hint>

      <mat-error *ngIf="formGroup.controls.newPassword.hasError('required')">New password is required</mat-error>

      <mat-error *ngIf="formGroup.controls.newPassword.hasError('minlength')">New password is too short</mat-error>
    </mat-form-field>

    <app-label [required]="true" [control]="formGroup.controls.confirmNewPassword" title="Confirm new password" #confirmNewPasswordLabel></app-label>
    <mat-form-field subscriptSizing="dynamic" class="with-suffix">
      <input
        (focusin)="confirmNewPasswordLabel.focus()"
        (focusout)="confirmNewPasswordLabel.focusout()"
        #confirmNewPasswordInput
        matInput
        placeholder="Confirm new password"
        name="confirmNewPassword"
        type="password"
        formControlName="confirmNewPassword"
        class="relative z-[1]"
      />

      <app-password-visibility [inputElement]="confirmNewPasswordInput" matSuffix></app-password-visibility>

      <mat-error *ngIf="formGroup.controls.confirmNewPassword.hasError('required')">
        Confirm new password is required
      </mat-error>

      <mat-error *ngIf="formGroup.controls.confirmNewPassword.hasError('minlength')">
        Confirm password is too short
      </mat-error>

      <mat-error *ngIf="formGroup.controls.confirmNewPassword.hasError('confirmPassword')">
        Passwords do not match
      </mat-error>
    </mat-form-field>

    <div class="pt-4 flex gap-3">
      <app-button
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onCancel()"
        [fluid]="true"
        class="flex-1"
      >
        Cancel
      </app-button>

      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        [loading]="loading"
        [fluid]="true"
        class="flex-1"
      >
        Update password
      </app-button>
    </div>
  </form>
</app-dialog-body>
