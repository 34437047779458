import { RegistrationState } from './registration-state.enum';

export class Organization {
  constructor(
    public name: string,
    public registrationState?: RegistrationState,
    public id?: string,
    public addressId?: string,
    public phoneNumber?: string,
    public customFields?: any,
    public pendingQuotaRequests?: Array<string>
  ) {}
}
