import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BillingPeriod, CustomerPrices } from 'src/app/_models/billing';
import { Plugin, PluginType } from 'src/app/_services/plugins.token';

@Component({
  selector: 'app-plugin-configuration',
  templateUrl: './plugin-configuration.component.html',
  styleUrls: ['./plugin-configuration.component.scss'],
})
export class PluginConfigurationComponent {
  @Input() plugin!: Plugin;
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;

  @Output() pluginRemove = new EventEmitter<PluginType>();
}
