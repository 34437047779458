import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';

// Preload icons for better UX (project-wise)
const PRELOAD_ICONS: string[] = [
  'alert-triangle',
  'arrow-right',
  'bank-note-01',
  'bar-chart-01',
  'book-open-01',
  'check-circle',
  'chevron-down',
  'cpu-chip-01',
  'cpu-chip-02',
  'dataflow-01',
  'eye-off',
  'eye',
  'file-02',
  'file-download-02',
  'graduation-hat-02',
  'headphones-01',
  'help-circle',
  'info-circle',
  'life-buoy-02',
  'link-external-01',
  'loading-02',
  'lock-01',
  'lock-04',
  'log-in-02',
  'log-out-01',
  'mail-01',
  'minus-circle',
  'monitor-01',
  'play-circle',
  'plus-circle',
  'plus',
  'refresh-cw-02',
  'send-03',
  'settings-01',
  'shield-dollar',
  'slash-circle-01',
  'stop-circle',
  'tick',
  'user-01',
  'user-circle',
  'user-plus-02',
  'x-circle',
  'x-close',
];

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnChanges {
  @Input() name!: string;

  static svgContentsByName = new Map<string, SafeHtml>();

  svgContents?: SafeHtml;

  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) {
    PRELOAD_ICONS.forEach((icon) => {
      if (SvgIconComponent.svgContentsByName.has(icon)) {
        return;
      }

      // Multiple components can be constructed simultaneously
      // so given the asynchronous nature of icons preloading
      // we initially set an empty string for the svgContents
      // so we don't end up preloading the same icon multiple times
      SvgIconComponent.svgContentsByName.set(icon, '');

      this.getIcon(icon).subscribe((svgContents) => {
        SvgIconComponent.svgContentsByName.set(icon, svgContents);
      });
    });
  }

  ngOnChanges(): void {
    const svgContents = SvgIconComponent.svgContentsByName.get(this.name);

    // Try using icons from cache
    if (svgContents) {
      this.svgContents = svgContents;
      return;
    }

    this.getIcon(this.name).subscribe((svgContents) => {
      this.svgContents = svgContents;
      // Cache already loaded icons
      SvgIconComponent.svgContentsByName.set(this.name, svgContents);
    });
  }

  private getIcon(name: string): Observable<SafeHtml> {
    return this.httpClient.get(`assets/svg/icons/${name}.icon.svg`, { responseType: 'text' }).pipe(
      map((svgContents) => {
        return this.sanitizer.bypassSecurityTrustHtml(svgContents);
      })
    );
  }
}
