import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/_services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';

@Component({
  selector: 'app-add-organization-member-dialog',
  templateUrl: './add-organization-member-dialog.component.html',
  styleUrls: ['./add-organization-member-dialog.component.scss'],
})
export class AddOrganizationMemberDialogComponent implements OnInit {
  readonly formGroup = this.formBuilder.group({
    fullName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;
  disableClose: boolean = false;
  organizationName?: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private matDialogRef: MatDialogRef<AddOrganizationMemberDialogComponent>
  ) {}

  ngOnInit(): void {
    this.organizationName = this.accountService.organizationValue?.name;
  }

  onCancel(): void {
    if (this.disableClose) {
      return;
    }

    this.matDialogRef.close(false);
  }

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    this.disableClose = true;
    this.matDialogRef.disableClose = true;

    this.accountService.addUser(this.formGroup.value).subscribe({
      next: () => {
        this.loading = false;
        this.matDialogRef.close(true);
      },
      error: () => {
        this.loading = false;
        this.disableClose = false;
        this.matDialogRef.disableClose = false;
      },
    });
  }
}
