import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardBrand, CardDetails } from 'src/app/_models/card-details';
import { ButtonPadding, ButtonStyle, ButtonType } from '../button/button.component';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent {
  @Input() cardDetails?: CardDetails;

  @Output() editClick = new EventEmitter<void>();

  readonly cardTitleByCardBrand: { [key in CardBrand]?: string } = {
    [CardBrand.AMEX]: 'American Express',
    [CardBrand.DINERS]: 'Diners',
    [CardBrand.DISCOVER]: 'Discover',
    [CardBrand.JCB]: 'JCB',
    [CardBrand.MASTERCARD]: 'MasterCard',
    [CardBrand.UNIONPAY]: 'UnionPay',
    [CardBrand.VISA]: 'Visa',
  };
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
}
