import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  private readonly gTagId = 'G-QJW2GS8DSM';

  constructor(private router: Router) {
    this.initializeGoogleAnalytics();
    this.trackPageViews();
  }

  private initializeGoogleAnalytics() {

    // Load the gtag script
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gTagId}`;
    document.head.appendChild(gTagManagerScript);

    // Initialize GA4
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${this.gTagId}');
    `;
    document.head.appendChild(gaScript);
  }

 private trackPageViews() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (window.gtag) {
          window.gtag!('config', this.gTagId, { page_path: event.urlAfterRedirects });
        }
      }
    });
  }
}
