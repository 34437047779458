import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { AccountService } from '../../_services/account.service';

const EMAIL_VERIFICATION_SUCCESS = 'E-mail verification successful';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private toastrService: ToastrService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    const { id } = this.route.snapshot.params;

    this.spinnerService.show();

    this.accountService.verifyMail(id).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.toastrService.success(EMAIL_VERIFICATION_SUCCESS);
        this.router.navigate(['/login']);
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
