import { Pipe, PipeTransform } from '@angular/core';
import { BillingPeriod, CustomerPrices } from '../_models/billing';
import { AgentType } from '../_services/agents.token';
import { AgentsControlValue } from '../cluster/agents-configuration/agents-configuration.component';
import { PluginTypesControlValue } from '../cluster/plugins-configuration/plugins-configuration.component';

@Pipe({
  name: 'totalClusterCosts',
})
export class TotalClusterCostsPipe implements PipeTransform {
  transform(
    customerPrices: CustomerPrices | undefined,
    agentsControlValue: AgentsControlValue,
    pluginTypesControlValue: PluginTypesControlValue,
    billingPeriod: BillingPeriod
  ): number {
    if (!customerPrices) {
      return 0;
    }

    const agentTypes = Object.keys(agentsControlValue) as AgentType[];

    let agentsCosts: number = 0;

    agentTypes.forEach((agentType) => {
      agentsCosts += agentsControlValue[agentType]! * customerPrices[agentType].amount;
    });

    let pluginsCosts: number = 0;

    pluginTypesControlValue.forEach((pluginType) => {
      pluginsCosts += customerPrices[pluginType].amount;
    });

    const minutePrice = customerPrices['controller'].amount + agentsCosts + pluginsCosts;

    return (minutePrice / 100) * billingPeriod.minutes;
  }
}
