<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 66 66" fill="none" class="animate-spin">
  <path
    d="M62 33C62 36.8083 61.2499 40.5794 59.7925 44.0978C58.3351 47.6163 56.199 50.8132 53.5061 53.5061C50.8132 56.199 47.6163 58.3351 44.0978 59.7925C40.5794 61.2499 36.8083 62 33 62C29.1917 62 25.4206 61.2499 21.9022 59.7925C18.3837 58.3351 15.1868 56.199 12.4939 53.5061C9.801 50.8132 7.66488 47.6163 6.20749 44.0978C4.7501 40.5794 4 36.8083 4 33C4 29.1917 4.75011 25.4206 6.2075 21.9022C7.66489 18.3837 9.80101 15.1868 12.4939 12.4939C15.1868 9.801 18.3837 7.66487 21.9022 6.20749C25.4206 4.7501 29.1917 4 33 4C36.8083 4 40.5794 4.75011 44.0978 6.2075C47.6163 7.66489 50.8132 9.80101 53.5061 12.4939C56.199 15.1868 58.3351 18.3838 59.7925 21.9022C61.2499 25.4206 62 29.1917 62 33L62 33Z"
    stroke-width="8"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="stroke-white"
  />

  <path
    d="M12.4939 12.4939C15.73 9.25783 19.686 6.83358 24.0385 5.41936C28.391 4.00514 33.0164 3.64112 37.5366 4.35704C42.0568 5.07296 46.3433 6.84851 50.0458 9.53851C53.7482 12.2285 56.7615 15.7566 58.8392 19.8343C60.9169 23.912 62 28.4235 62 33C62 37.5765 60.9169 42.088 58.8392 46.1657C56.7615 50.2434 53.7482 53.7715 50.0458 56.4615C46.3433 59.1515 42.0567 60.927 37.5366 61.643"
    stroke="#0083C5"
    stroke-width="8"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-dasharray="109.32749938964844"
    stroke-dashoffset="54.66374969482422"
    class="animate-[spinnerStroke_1.5s_ease-in-out_infinite] stroke-primary-600"
  />
</svg>
