import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { SpinnerService } from '../_services/spinner.service';

const INTERNAL_SERVER_ERROR = 'Internal server error';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private toastrService: ToastrService,
    private accountService: AccountService,
    private spinnerService: SpinnerService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.processError(error);
        this.spinnerService.hide();

        return throwError(() => error);
      }),
    );
  }

  private processError(error: HttpErrorResponse): void {
    if (error.status === 401) {
      this.accountService.handleLogout();
    }

    const errorMessage = error.error?.errorMessage || INTERNAL_SERVER_ERROR;

    this.toastrService.error(errorMessage);
  }
}
