import { animate, state, style, transition, trigger } from '@angular/animations';

// Source: https://github.com/angular/components/blob/main/src/material/form-field/form-field-animations.ts
export const transitionMessages = trigger('transitionMessages', [
  state('enter', style({ opacity: 1, transform: 'translateY(0%)' })),
  transition('void => enter', [
    style({ opacity: 0, transform: 'translateY(-5px)' }),
    animate('300ms cubic-bezier(0.55, 0, 0.55, 0.2)'),
  ]),
]);
