import { BillingItem } from './billing';
import { StepComponent } from './component';

export interface Cluster {
  id: string;
  name: string;
  state: ClusterStatus;
  version: string;
  url: string;
  autoStartStop: boolean;
  gracePeriodS: number;
  subscriptionId: string;
  agentPools: AgentPool[];
  plugins: PluginPool[];
}

export interface AgentPool {
  key: string;
  tech: string;
  type: string;
  configured: number;
  running: number;
  description?: string;
  details?: StepComponent;
}

export interface PluginPool {
  key: string;
  name: string;
  description: string;
}

export enum ClusterStatus {
  CREATING = 'CREATING',
  CREATION_FAILED = 'CREATION_FAILED',
  RUNNING = 'RUNNING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  STARTING = 'STARTING',
  DESTROYING = 'DESTROYING',
  DESTROYED = 'DESTROYED',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  UPGRADING = 'UPGRADING',
}

export interface AgentPoolUsage {
  poolKey: string;
  usage: number;
}

export interface ClusterListItem {
  id: string;
  name: string;
  url: string;
  state: ClusterStatus;
  organizationId: string;
  billableItem: BillingItem[];
  configuredNbAgents: AgentPoolUsage[];
  autoStartStop: boolean;
  backupEnabled: boolean;
  gracePeriod: number;
}

export interface GetClusterResponse {
  id: string;
  name: string;
  url: string;
  state: ClusterStatus;
  organizationId: string;
  autoStartStop: boolean;
  gracePeriodS: number;
  agentPools: AgentPool[];
  plugins: PluginPool[];
}
